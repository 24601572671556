.contact-form-instructions {
  color: #1e1e1e;
  margin-bottom: 4rem;
  margin-top: 4rem;
  width: 40%;
  margin-left: 30%;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
}

.contact-form-input {
  width: 635px;
  height: 50px;
  margin-bottom: 1rem;
  border-left: none;
  border-right: none;
  border-top: 0.75px solid rgb(0, 0, 0, 0.43);
  border-bottom: 0.75px solid rgb(0, 0, 0, 0.43);
  color: rgb(0, 0, 0, 0.23);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus {
  border-top: 0.75px solid #2f80ed;
  border-bottom: 0.75px solid #2f80ed;
  border-left: none;
  border-right: none;
  color: #1e1e1e;
}

/* input[type=text]:invalid,
  input[type=tel]:invalid,
  input[type=email]:invalid {
    border-top: 0.75px solid #EB5757;
    border-bottom: 0.75px solid #EB5757;
    border-left: none;
    border-right: none;
    color: #1e1e1e;
    background: url(../media/img/error.svg) no-repeat right center;
    background-position-x: 98%;
  } */

input[type="text"]:valid,
input[type="tel"]:valid,
input[type="email"]:valid {
  border-top: 0.75px solid #219653;
  border-bottom: 0.75px solid #219653;
  border-left: none;
  border-right: none;
  color: #1e1e1e;
  background: url(../../media/img/checkmark.svg) no-repeat right center;
  background-position-x: 98%;
}

/* Media Quieres */
@media screen and (max-width: 1400px) {
  .contact-form-instructions {
    width: 70%;
    margin-left: 15%;
  }
}

@media screen and (max-width: 768px) {
  .contact-form-instructions {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (max-width: 650px) {
  .contact-form-input {
    width: 80%;
  }
}
