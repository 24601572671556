.item-1-35 {
  z-index: 2;
  opacity: 1;
  background: url("../../media/img/layout-35-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-2-35 {
  background: url("../../media/img/layout-35-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-3-35 {
  background: url("../../media/img/layout-35-3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

*:target ~ .item-1-35 {
  opacity: 0;
}

#item-1-35:target ~ .item-1-35 {
  opacity: 1;
}

#item-2-35:target ~ .item-2-35,
#item-3-35:target ~ .item-3-35 {
  z-index: 3;
  opacity: 1;
}
