/* Featured */
.yacht-selector-hero {
  height: 100%;
  width: 100vw;
  padding-bottom: 6rem;
}

.yacht-selector-hero-title {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

/* Yachts Container Content */
.yacht-selector-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  justify-content: space-evenly;
  align-items: flex-start;
}

.yacht-selector {
  height: auto;
  width: 285px;
  margin-bottom: 4rem;
}

.yacht-selector-image-1,
.yacht-selector-image-2,
.yacht-selector-image-3,
.yacht-selector-image-4 {
  width: 285px;
  height: 195px;
}

.yacht-selector-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Media Quieres */
@media screen and (max-width: 1440px) {
  .yacht-selector-hero {
    padding-bottom: 0rem;
  }
}

@media screen and (max-width: 428px) {
  .yacht-selector-hero-title {
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
}
