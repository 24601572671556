.buildyachtmodels-hero {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  margin-bottom: 8rem;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.buildyachtmodels-image-1 {
  position: relative;
  height: 525px;
  width: auto;
  z-index: 1;
  left: 50px;
}

.buildyachtmodels-card {
  position: relative;
  width: 600px;
  height: 340px;
  z-index: 2;
  padding: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.buildyachtmodels-card-title {
  text-align: left !important;
  width: 100%;
}

.buildyachtmodels-card-subtitle {
  text-align: left !important;
  margin-bottom: 2rem;
  width: 80%;
}

.buildyachtmodels-image-2 {
  position: relative;
  height: 525px;
  width: auto;
  z-index: 1;
  right: 50px;
}

.ButtonBuildYachtModels {
  margin-left: 0%;
}

/* Media Quieres */
@media screen and (max-width: 1418px) {
  .buildyachtmodels-image-1 {
    left: 0px;
  }
  .buildyachtmodels-image-2 {
    display: none;
  }
  .buildyachtmodels-image-1 {
    width: 90%;
    height: auto;
    order: 1;
  }
  .buildyachtmodels-hero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0px;
  }
  .buildyachtmodels-card {
    width: 70%;
    height: auto;
    right: 0px;
    padding: 20px;
    padding-bottom: 40px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .buildyachtmodels-card-title,
  .buildyachtmodels-card-subtitle {
    text-align: center !important;
  }
}

@media screen and (max-width: 428px) {
  .buildyachtmodels-card {
    padding-bottom: 40px;
  }
}
