/* Landing */
.landing-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("../../../media/img/landingherobg-desktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 90vh;
  left: 0px;
  top: 0px;
}

.landing-header {
  position: absolute;
  width: 100%;
  height: 101px;
  bottom: 0%;
}

/* Media Quieres */
@media screen and (max-width: 1100px) {
  .landing-header {
    height: 1px;
    bottom: 50%;
  }
  .landing-hero {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../../media/img/landingherobg-mobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .landing-header {
    height: 101px;
    bottom: 50%;
  }
}

@media screen and (max-width: 428px) {
  .landing-hero {
    height: 100vh;
  }
}
