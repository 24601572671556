.external-paint-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
  height: 80vh;
  width: 60vw;
}

.external-paint-colors {
  width: 60vw;
  height: 25vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.external-paint-price {
  margin-bottom: 1rem;
}

.external-paint-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 2px solid transparent;
}

.external-paint-color:hover {
  border: 2px solid #1e1e1e;
}

.external-paint-color-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid #1e1e1e;
  margin-top: 1rem;
}

.external-paint-buttons-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.external-paint-title-adaptive {
  display: none;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .external-paint-container {
    width: 100vw;
    height: 40%;
    margin-bottom: 2rem;
  }
  .external-paint-colors {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .external-paint-buttons-container {
    position: relative;
    width: 100vw;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0rem;
  }
  .external-paint-color {
    width: 40%;
    margin-bottom: 2rem;
  }
  .external-paint-title-adaptive {
    display: block;
    margin-bottom: 2rem;
  }
}
