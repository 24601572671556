.slider-image {
  height: 370px;
  width: 100%;
}

.overflow-remove {
  overflow-x: hidden;
}

/* Media Quieres */
@media screen and (max-width: 768px) {
  .slider-image {
    height: 300px;
    width: 100%;
    margin-bottom: 3rem;
  }
}