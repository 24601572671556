.overview-preview-top {
  width: 100%;
  height: 30vh;
  background: url(../../../media/img/overview-preview.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overview-preview-bottom {
  width: 100%;
  height: 40vh;
}

.overview-preview-container {
  width: 40vw;
  height: 70vh;
  position: absolute;
  top: 25%;
  right: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.overview-preview-options-table {
  width: 75%;
  height: auto;
  margin-top: 4rem;
  margin-left: 10%;
}

.overview-preview-option-row {
  margin-top: 14px;
  height: 30px;
  width: 100%;
  border-bottom: #fff 1px solid;
}

.overview-preview-option-title {
  text-align: right;
  color: #fff;
  float: left;
  white-space: wrap;
}

.overview-preview-option-price {
  text-align: right;
  color: #fff;
  float: right;
}

.overview-preview-total-price {
  text-align: right;
  color: #fff;
  margin-right: 15%;
  margin-top: 14px;
}

@media screen and (max-width: 1024px) {
  .overview-preview-top {
    width: 100%;
    height: 30vh;
  }
  .overview-preview-bottom {
    width: 100%;
    height: 40vh;
  }
  .overview-preview-container {
    width: 100vw;
    height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .overview-preview-options-table {
    width: 100%;
    height: auto;
    margin-top: 4rem;
    margin-left: 0%;
  }
  .overview-preview-option-row {
    width: 80%;
    margin-left: 10%;
  }
  .overview-preview-total-price {
    margin-right: 10%;
  }
  .overview-preview-option-title,
  .overview-preview-option-price {
    font-size: 80% !important;
  }
}

@media screen and (max-width: 768px) {
  .overview-preview-top {
    height: 20vh;
  }
  .overview-preview-bottom {
    height: 30vh;
  }
  .overview-preview-container {
    height: 50vh;
  }
}
