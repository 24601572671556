/* Popups */
.modal {
  font-size: 12px;
}

.modal > .header {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 2rem;
  margin-top: 4rem;
}

.modal > .header-country {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.modal > .content {
  width: 100%;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 15px;
  top: 15px;
  font-size: 32px;
  background: #fff;
  border: none;
}

.dealer-container {
  width: 80%;
  margin-left: 10%;
  height: 100%;
  border: 2px solid #ccc;
  margin-bottom: 1.5rem;
}

.dealer-container:hover {
  width: 80%;
  margin-left: 10%;
  height: 100%;
  border: 2px solid #000;
  margin-bottom: 1.5rem;
}

.dealer-title {
  margin-top: 1rem;
  margin-block-end: 0em;
  margin-block-start: 1.5rem;
}

.dealer-location {
  margin-bottom: 1.25rem;
}

/* Base */
.popup-content {
  margin: auto;
  background: #fff;
  width: 50%;
  padding: 5px;
}

[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Media Quieres */
@media screen and (max-width: 768px) {
  .popup-content {
    width: 70% !important;
  }
}

@media screen and (max-width: 428px) {
  .dealer-container {
    width: 90%;
    margin-left: 5%;
  }
  .modal > .header {
    width: 100%;
    margin-left: 0%;
  }
  .popup-content {
    width: 90% !important;
  }
}
