.model-gallery-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 6rem;
  height: 100%;
}

.model-gallery-hero-title {
  margin-top: 6rem;
}

.model-gallery-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 505px;
  width: auto;
}

.model-gallery-image-side {
  height: 155px;
  width: 215px;
}

.model-gallery-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 760px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.model-gallery-image-center {
  height: 505px;
  width: 760px;
}

@media screen and (max-width: 1200px) {
  .model-gallery-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .model-gallery-image-side {
    height: 248px;
    width: 344px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .model-gallery-center {
    margin-bottom: 1.5rem;
    height: 100%;
    width: 100%;
    order: 0;
  }
  .model-gallery-side {
    height: 100%;
    order: 1;
  }
  .model-gallery-image-side {
    margin-bottom: 1.5rem;
  }
  .model-gallery-image-center {
    height: auto;
    width: 72%;
  }
}

@media screen and (max-width: 1024px) {
  .model-gallery-center {
    order: 2;
  }
  .model-gallery-image-center {
    height: 248px;
    width: 344px;
  }
}

/* @media screen and (max-width: 428px) {
  .model-gallery-container {
    flex-direction: column;
  }
} */
