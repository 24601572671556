/* Fonts */
@font-face {
  font-family: Lovelace;
  src: url("../fonts/Lovelace-Regular.ttf");
}
@font-face {
  font-family: Vipnagorgialla;
  src: url("../fonts/VipnagorgiallaRg-Italic.otf");
}
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap");

h1 {
  font-size: 72px;
  line-height: 101px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h2 {
  font-size: 60px;
  line-height: 84px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h3 {
  font-size: 44px;
  list-height: 62px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h4 {
  font-size: 32px;
  line-height: 45px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h5 {
  font-size: 22px;
  line-height: 31px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h6 {
  font-size: 18px;
  line-height: 25px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

.body-text-lg {
  font-size: 18px;
  line-height: 24px;
  color: #1e1e1e;
  font-family: "Work Sans", sans-serif;
  text-align: center;
}

.nav-text {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.body-text-md-light {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
}

.body-text-sm-light {
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
}

.arb-light {
  font-family: Vipnagorgialla;
  font-size: 11px;
  line-height: 13px;
  text-align: center !important;
  letter-spacing: 0.03rem;
  color: #fff;
  white-space: nowrap;
}

.arb-dark {
  font-family: Vipnagorgialla;
  font-size: 11px;
  line-height: 13px;
  text-align: center !important;
  letter-spacing: 0.03rem;
  color: #1e1e1e;
  white-space: nowrap;
}

span,
p,
input {
  font-family: "Work Sans", sans-serif;
}

a.button {
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
}

@media screen and (max-width: 1440px) {
  .nav-text {
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .nav-text {
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 44px;
    line-height: 62px;
  }
  h2 {
    font-size: 32px;
    line-height: 45px;
  }
  h3 {
    font-size: 22px;
    line-height: 31px;
  }
  h4 {
    font-size: 18px;
    line-height: 25px;
  }
  .body-text-lg {
    font-size: 16px;
    line-height: 21px;
  }
}
