/* Containers */
.about-container {
  width: 100vw;
}

.about-ce-container,
.about-research-container,
.about-abyc-container,
.about-persona-1-container,
.about-persona-2-container,
.about-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 8rem;
  margin-bottom: 8rem;
  align-items: center;
  height: 100%;
}

.about-ce-content-container,
.about-intro-hero,
.about-abyc-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.about-persona-1-content-container,
.about-persona-2-content-container,
.about-info-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* Text */
.about-intro-quote {
  width: 60%;
  margin-left: 20%;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.about-abyc-title,
.about-persona-1-title,
.about-persona-2-title,
.about-ce-title {
  line-height: 0px;
}

.about-research-title {
  text-align: right !important;
}

.about-ce-title,
.about-persona-1-title,
.about-persona-2-title,
.about-persona-1-paragraph,
.about-abyc-paragraph,
.about-ce-paragraph,
.about-persona-2-paragraph,
.about-research-paragraph,
.about-info-paragraph {
  text-align: left !important;
  width: 390px;
  height: auto;
}

/* Images */
.about-abyc-image {
  width: auto;
  height: 360px;
}

.about-ce-image {
  width: 840px;
  height: 400px;
}

.about-ce-logo {
  height: 60px;
  width: auto;
  margin-left: 1rem;
}

.about-intro-image {
  width: 90%;
  height: auto;
  margin-left: 10%;
  margin-bottom: 5rem;
}

.about-research-image {
  width: 315px;
  height: 315px;
  margin-right: 4rem;
  margin-left: 4rem;
}

.about-persona-1-image,
.about-persona-2-image {
  height: 465px;
  width: auto;
}

.about-info-image-1,
.about-info-image-2 {
  width: 600px;
  height: auto;
}

.about-info-image-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

strong {
  font-size: 125% !important;
}

/* Media Quieres */
@media screen and (max-width: 1300px) {
  .about-ce-image {
    width: 50%;
    height: auto;
  }
  .about-research-image {
    width: 25%;
    height: auto;
  }
  .about-research-title,
  .about-research-paragraph {
    width: 300px;
    height: auto;
  }
  .about-abyc-image {
    width: 40%;
    height: auto;
  }
  .about-ce-container,
  .about-abyc-container,
  .about-research-container {
    margin-bottom: 5rem;
  }
  .about-info-image-1,
  .about-info-image-2 {
    width: 400px;
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .about-persona-1-image,
  .about-persona-2-image {
    width: 300px;
    height: auto;
  }
  .about-persona-1-container,
  .about-persona-2-container,
  .about-info-container {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 950px) {
  .about-ce-container,
  .about-abyc-container,
  .about-research-container {
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    height: 100%;
    margin-top: 2rem;
  }
  .about-ce-image {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
  .about-research-image,
  .about-abyc-image {
    width: 40%;
    height: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-ce-paragraph,
  .about-ce-title,
  .about-abyc-paragraph,
  .about-abyc-title {
    width: 90%;
    margin-left: 5%;
  }
  .about-research-paragraph,
  .about-research-title {
    width: 90%;
    order: 2;
  }
  .about-ce-logo {
    height: 40px;
  }
  .about-ce-title,
  .about-abyc-title,
  .about-research-title,
  .about-ce-paragraph,
  .about-ce-title,
  .about-abyc-paragraph,
  .about-abyc-title,
  .about-research-paragraph {
    text-align: center !important;
  }
}

@media screen and (max-width: 850px) {
  .about-persona-1-container,
  .about-persona-2-container,
  .about-info-container {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .about-persona-1-paragraph,
  .about-persona-1-title,
  .about-persona-2-paragraph,
  .about-persona-2-title,
  .about-info-paragraph {
    width: 90%;
    margin-left: 5%;
  }
  .about-persona-2-content-container,
  .about-info-paragraph {
    order: 2;
  }
  .about-persona-1-image,
  .about-persona-2-image {
    width: 40%;
    height: auto;
    margin-bottom: 2rem;
  }
  .about-info-image-2 {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 100%;
    height: auto;
  }
  .about-info-image-1 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .about-intro-quote {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 0rem;
    margin-top: 3.5rem;
  }
  .about-intro-image {
    width: 100%;
    margin-left: 0rem;
  }
  .about-persona-2-paragraph,
  .about-persona-1-paragraph,
  .about-persona-1-title,
  .about-persona-2-title,
  .about-info-paragraph {
    text-align: center !important;
  }
}

@media screen and (max-width: 450px) {
  .about-ce-image {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-research-image,
  .about-abyc-image {
    width: 40%;
    height: auto;
  }
}

@media screen and (max-width: 428px) {
  .about-persona-1-image,
  .about-persona-2-image,
  .about-info-image-1,
  .about-info-image-2 {
    width: 100%;
    height: auto;
  }
  .about-persona-1-title,
  .about-persona-2-title {
    margin-top: 6rem;
  }
  .about-persona-2-paragraph {
    margin-bottom: 2rem;
  }
  .about-persona-2-content-container {
    order: 2;
  }
  .about-info-image-1,
  .about-info-image-2 {
    margin-top: 0rem;
    margin-bottom: 4rem;
  }
}
