/* Dealer*/
.dealer-hero {
  width: 100vw;
  height: 100vh;
  position: relative;
}

/* Images */
.photo-1 {
  position: absolute;
  width: 390px;
  height: 225px;
  top: 0%;
  left: 0%;
}

.photo-2 {
  position: absolute;
  width: 345px;
  height: 225px;
  top: 36%;
  left: 7%;
}

.photo-3 {
  position: absolute;
  width: 315px;
  height: 225px;
  top: 70%;
  left: 0%;
}

.photo-4 {
  position: absolute;
  width: 390px;
  height: 225px;
  top: 70%;
  right: 5%;
}

.photo-5 {
  position: absolute;
  width: 315px;
  height: 225px;
  top: 36%;
  right: 0%;
}

.photo-6 {
  position: absolute;
  width: 390px;
  height: 225px;
  top: 0%;
  right: 7%;
}

.photo-7 {
  display: none;
}

/* Dealer Content*/
.dealer-content {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dealer-title {
  width: 100%;
  margin-top: 0rem;
}

.dealer-subtitle {
  width: 30%;
  margin-bottom: 4rem;
}

.dealer-dropdown {
  width: 20%;
  font-family: "Work Sans", sans-serif;
}

/* Media Quieres */
@media screen and (max-width: 1340px) {
  .dealer-content {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }
  .dealer-hero {
    width: 100vw;
    height: auto;
  }
  .photo-1,
  .photo-2,
  .photo-3,
  .photo-4,
  .photo-5,
  .photo-6 {
    display: none;
  }
  .dealer-subtitle {
    width: 70%;
  }
  .dealer-dropdown {
    width: 50%;
  }
  .photo-7 {
    margin-top: 4rem;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    width: 100%;
    height: auto;
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .dealer-subtitle {
    width: 80%;
  }
}

@media screen and (max-width: 428px) {
  .dealer-dropdown {
    width: 80%;
  }
  .dealer-title {
    margin-top: 4rem;
  }
}
