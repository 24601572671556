.dropdown-menu {
  background: #ccc;
  width: 120px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  z-index: 100;
  padding-left: 0px;
  margin-left: 100px;
}

.dropdown-menu li {
  background: #eee;
  cursor: pointer;
  text-decoration: none;
  color: #1e1e1e;
}

.dropdown-menu li:hover {
  background: #ccc;
  color: #1e1e1e;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #1e1e1e;
  padding: 16px;
}

.dropdown-link-header {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

.dropdown-menu-language {
  background: #ccc;
  width: 120px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  z-index: 100;
  padding-left: 0px;
  margin-left: 0px;
}

.dropdown-menu-language li {
  background: #eee;
  cursor: pointer;
  text-decoration: none;
  color: #1e1e1e;
}

.dropdown-menu-language li:hover {
  background: #ccc;
  color: #1e1e1e;
}

.dropdown-menu-language.clicked {
  display: none;
}