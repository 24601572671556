/* News */
.news-hero {
  height: 100%;
  width: 100vw;
}

.news-hero-title {
  position: relative;
  text-align: left !important;
  margin-bottom: 2rem;
  margin-top: 0rem;
  margin-left: 4rem;
}

/* News Articles */
.news-articles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 4rem;
}

.news-article {
  position: relative;
  width: 400px;
  height: auto;
  margin-bottom: 4rem;
}

/* News Articles Content */
.news-article-date {
  position: relative;
  width: 400px;
  height: 16px;
  text-align: left !important;
}

.news-article-title {
  position: relative;
  width: 100px;
  height: 16px;
  text-align: left !important;
}

.news-article-description {
  position: relative;
  width: 400px;
  height: 42px;
  text-align: left !important;
}

.news-article-image {
  width: 400px;
  height: auto;
  box-sizing: border-box;
  border: 4px solid transparent;
}

.news-article-image:hover {
  border: 4px solid #2F80ED;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .news-hero-title {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: 0rem;
  }
}

@media screen and (max-width: 768px) {
.news-hero-title {
  text-align: center !important;
}
}

@media screen and (max-width: 428px) {
  .news-article-image {
    width: 100%;
  }
  .news-article-description {
    width: 100%;
  }
  .news-article {
    width: 90%;
  }
  .news-article-date {
    width: 100%;
  }
}
