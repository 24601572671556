.engine-options-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
  height: 90vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
}

.engine-options-buttons-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.engine-options-step-title {
  color: #1e1e1e;
  margin-left: 5vw;
  text-align: left !important;
}

.fuel-type-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 6vw;
}

.fuel-type-option {
  width: 115px;
  height: 60px;
  border: 2px solid #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5%;
}

.fuel-type-option:hover {
  background-color: #1e1e1e;
}

.fuel-type-option:hover .fuel-type-title {
  color: #fff;
}

.fuel-type-title {
  color: #1e1e1e;
}

.engine-type-table {
  width: 80%;
  margin-left: 10%;
}

.engine-type-table-row {
  width: 100%;
  height: 50px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  border: 1px solid #1e1e1e;
  padding-inline-start: 0px;
}

.engine-type-table-row:hover .engine-type-table-text {
  color: #fff;
}

.engine-type-table-row:hover {
  background-color: #1e1e1e;
  border: 1px solid #1e1e1e;
}

.engine-type-table-text {
  color: #1e1e1e;
  margin-right: 10px;
  width: 80%;
}

.model {
  width: 50%;
  text-align: left;
  margin-left: 1rem;
}

.price {
  width: 50%;
  text-align: right;
  margin-right: 1rem;
}

.engine-options-title-adaptive {
  display: none;
}

.step-banner {
  display: none;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .engine-options-container {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .engine-options-buttons-container {
    width: 100vw;
    justify-content: space-evenly;
    margin-bottom: 0rem;
  }
  .engine-options-step-title {
    display: none;
  }
  .fuel-type-container {
    justify-content: center;
    margin-left: 0vw;
  }
  .fuel-type-option {
    width: 100px;
    height: 100px;
    margin-right: 2rem;
    margin-left: 2rem;
    border-radius: 50%;
  }
  .engine-options-title-adaptive {
    display: block;
    color: #1e1e1e;
    margin-bottom: 2rem;
  }
  .step-banner {
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 428px) {
  .engine-options-buttons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .engine-type-table-text {
    font-size: 70% !important;
  }
}
