.navbar-nav-link-dropdown {
  margin-top: 12px;
  margin-right: 30px;
  color: #1e1e1e;
  padding-bottom: 6px;
}

.header-nav-link-dropdown {
  margin-top: 12px;
  margin-right: 30px;
  color: #1e1e1e;
  padding-bottom: 6px;
}

.ml-1 {
  margin-left: 15px;
}

/* NavBar */
.navbar-hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80vw;
  padding-left: 10%;
  white-space: nowrap;
  padding-top: 30px;
  z-index: 999;
}

/* NavBar Brand */
.navbar-brand {
  width: 25vw;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

.navbar-tethys-logo {
  height: 31px;
  margin-top: 8px;
}

a:-webkit-any-link {
  text-decoration: none;
}

.navbar-vertical-divider {
  border: 1px solid #1e1e1e;
  transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
}

.header-vertical-divider {
  border: 1px solid #fff;
  transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
}

/* NavBar Start */
.navbar-start {
  width: 50vw;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

/* NavBar End */
.navbar-end {
  width: 25vw;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

.navbar-burger-lines__line1 {
  opacity: 0.7;
  border: 1px solid #1e1e1e;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 30px;
  height: 0px;
  right: 30px;
  top: 44px;
}

.navbar-burger-lines__line2 {
  opacity: 0.7;
  border: 1px solid #1e1e1e;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 20px;
  height: 0px;
  right: 30px;
  top: 51px;
}

.navbar-burger-lines__line3 {
  opacity: 0.7;
  border: 1px solid #1e1e1e;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 10px;
  height: 0px;
  right: 30px;
  top: 58px;
}

.header-burger-lines__line1 {
  opacity: 0.7;
  border: 1px solid #fff;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 30px;
  height: 0px;
  right: 30px;
  top: 44px;
}

.header-burger-lines__line2 {
  opacity: 0.7;
  border: 1px solid #fff;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 20px;
  height: 0px;
  right: 30px;
  top: 51px;
}

.header-burger-lines__line3 {
  opacity: 0.7;
  border: 1px solid #fff;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 10px;
  height: 0px;
  right: 30px;
  top: 58px;
}

/* NavBar Content */
.navbar-nav-link {
  margin-right: 50px;
  margin-top: 12px;
  color: #1e1e1e;
  padding-bottom: 6px;
}

.header-nav-link {
  margin-right: 50px;
  margin-top: 12px;
  color: #fff;
  padding-bottom: 6px;
}

.navbar-nav-link:last-of-type {
  margin-right: 0px;
}

.navbar-link,
.navbar-nav-link,
.navbar-nav-link-dropdown {
  text-decoration: none;
}

.navbar-nav-link:hover,
.navbar-nav-link-dropdown:hover {
  border-bottom: #1e1e1e 2px solid;
}

.navbar-dropdown-arrow {
  border: solid #1e1e1e;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 5px;
  margin-bottom: 3px;
}

.header-nav-link:hover,
.header-nav-link-dropdown:hover {
  border-bottom: #fff 2px solid;
}

.header-dropdown-arrow {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 5px;
  margin-bottom: 3px;
}

.social-icon-container {
  margin-left: 2rem;
  position: absolute;
  top: 40px;
  right: 100px;
}

.social-icon {
  height: 30px;
  margin-right: 15px;
}

/* Media Quieries */
@media screen and (max-width: 1440px) {
  .navbar-start {
    width: 50vw;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 999;
  }
  .navbar-nav-link,
  .navbar-nav-link-dropdown {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .social-icon-container {
    margin-left: 2rem;
    position: absolute;
    top: 40px;
    right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-nav-link,
  .navbar-nav-link-dropdown {
    margin-right: 30px;
  }
}

@media screen and (min-width: 901px) {
  .navbar-end .navbar-burger-lines__line1,
  .navbar-end .navbar-burger-lines__line2,
  .navbar-end .navbar-burger-lines__line3 {
    display: none;
  }
  .navbar-end .header-burger-lines__line1,
  .navbar-end .header-burger-lines__line2,
  .navbar-end .header-burger-lines__line3 {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .navbar-brand {
    width: 100vw;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    z-index: 999;
    position: relative;
    left: 0;
  }
  .social-icon-container {
    margin-left: 2rem;
    position: absolute;
    top: 40px;
    right: 125px;
  }
  .navbar-tethys-logo {
    padding-left: 30px;
  }
  .navbar-start {
    display: none;
  }
  .navbar-end .navbar-nav-link,
  .navbar-end .navbar-nav-link-dropdown {
    display: none;
  }
  .navbar-end .header-nav-link,
  .navbar-end .header-nav-link-dropdown {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .social-icon-container {
    display: none;
  }
}