.equipment-options-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
  height: 80vh;
  width: 60vw;
}

.equipment-options-buttons-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.equipment-options-button-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

.option-type-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
}

.option-type-option {
  width: 115px;
  height: 60px;
  border: 2px solid #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5%;
}

.option-type-title {
  color: #1e1e1e;
}

.is-active-option {
  background-color: #1e1e1e !important;
  color: #fff !important;
}

.option-type-option:hover {
  background-color: #1e1e1e;
}

.option-type-option:hover .option-type-title {
  color: #fff;
}

.option-container:hover {
  border: #1e1e1e 2px solid;
}

.option-img {
  width: 180px;
  height: 125px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.option-title {
  color: #1e1e1e;
  white-space: nowrap;
}

.option-price {
  color: #1e1e1e;
}

.option-container {
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border: transparent 2px solid;
}

.options-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.equipment-options-title-adaptive {
  display: none;
}

@media screen and (max-width: 1024px) {
  .equipment-options-container {
    margin-top: 4rem;
    margin-bottom: 2rem;
    height: 80%;
    width: 100vw;
  }
  .equipment-options-title-adaptive {
    display: block;
    color: #1e1e1e;
    margin-bottom: 2rem;
  }
  .equipment-options-buttons-container {
    width: 100vw;
    justify-content: space-evenly;
  }
  .equipment-options-button-container {
    width: 100vw;
  }
  .options-container {
    width: 100vw;
    margin-left: 0vw;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .option-container {
    width: 40%;
    height: auto;
    justify-content: space-evenly;
  }
  .option-type-container {
    display: none;
  }
}

@media screen and (max-width: 428px) {
  .equipment-options-buttons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 389px) {
  .option-container {
    width: 50%;
  }
}