/* Footer */
.footer-top {
  height: 350px;
  display: flex;
  flex-direction: row;
}

/* Footer Brand */
.footer-brand {
  width: 30vw;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  height: 50px;
}

.footer-logo-vertical-divider {
  border: 1px solid #fff;
  transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
}

.footer-tethys-logo {
  height: 31px;
  margin-top: 8px;
}

/* Footer Categories */
.footer-categories {
  display: flex;
  flex-direction: row;
}

/* Footer Models */
.footer-category-models {
  width: 20vw;
  padding-top: 50px;
}

/* Footer Contact */
.footer-category-contact {
  width: 25vw;
  padding-top: 50px;
}

/* Footer Build */
.footer-category-build {
  width: 25vw;
  padding-top: 50px;
}

/* Footer Category Content */
.footer-category-list {
  list-style: none;
  color: #fff;
  padding-inline-start: 0px;
  max-width: 100%;
}

.footer-category-list__item {
  margin-top: 15px;
}

.footer-category-list__item:first-child {
  margin-top: 30px;
}

button.footer-span-item {
  border: none;
  color: #fff;
  padding-left: 0px;
  padding-bottom: 6px;
  border-bottom: transparent 2px solid;
}

span.footer-span-item {
  padding-bottom: 6px;
}

button.footer-span-item:hover {
  border-bottom: #fff 2px solid;
}

span.footer-span-item:hover {
  border-bottom: #fff 2px solid;
}

.footer-desc {
  width: 200px;
}

/* Copyright */
.footer-copyright {
  height: 80px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer-copyright-text {
  color: #fff;
  font-size: 10px;
  margin-bottom: 2rem;
}

.footer-social-icon {
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
}

/* Tooltip */
.tooltip-container {
  position: relative;
}

.tooltip-box {
  position: absolute;
  background: #2F80ED;
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  top: -5px;
  left: 110%;
  display: none;
  width: 100px;
  padding: 5px 0;
  z-index: 1;
}

.tooltip-box.visible {
  display: block;
}

.tooltip-arrow {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #2F80ED transparent transparent;
}


/* Media Quieres */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .footer-top {
    flex-direction: column;
    height: auto;
    padding-bottom: 40px;
  }
  .footer-brand {
    width: 100vw;
    padding-left: 0px;
  }
  .footer-categories {
    display: flex;
    flex-direction: row;
  }
  .footer-category-models {
    width: 33vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .footer-category-contact {
    width: 34vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }
  .footer-category-build {
    width: 33vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: center;
    margin-bottom: 50px;
  }
  .footer-category-list__item:first-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    height: auto;
  }
  .footer-brand {
    width: 100vw;
    padding-left: 0px;
  }
  .footer-categories {
    display: flex;
    flex-direction: column;
  }
  .footer-category-models {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    order: 2;
    padding-top: 0px;
  }
  .footer-category-contact {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    order: 2;
    margin-bottom: 4rem;
    text-align: center !important;
  }
  .footer-category-build {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    text-align: center !important;
  }
  .footer-category-list__item:first-child {
    margin-top: 0px;
  }
  .footer-category-models .footer-category-list__item {
    display: inline;
    margin-right: 30px;
    margin-top: 15px;
  }
  .footer-category-models .footer-category-list__item:last-of-type {
    display: inline;
    margin-right: 0px;
  }
  .footer-desc {
    width: 200px;
    margin: auto;
  }
}
