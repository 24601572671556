.carousel-wrapper {
  height: 50vh;
  position: relative;
  width: 50vw;
  margin: 0rem auto 3rem auto;
}

.carousel-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 50px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.arrow-carousel {
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 12px;
}

.arrow-prev {
  left: -10%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(135deg);
}

.arrow-next {
  right: -10%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.light {
  color: white;
}

[id^="item"] {
  display: none;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .carousel-wrapper {
    height: 40vh;
    position: relative;
    width: 70vw;
    margin: 0rem auto 2rem auto;
  }
}

@media screen and (max-width: 900px) {
  .carousel-wrapper {
    height: 30vh;
    position: relative;
    width: 75vw;
    margin: 0rem auto 0rem auto;
  }
}

@media (max-width: 480px) {
  .arrow-carousel,
  .light .arrow-carousel {
    background-size: 10px;
    background-position: 10px 50%;
  }
}
