/* Featured */
.featured-hero {
  height: 100%;
  width: 100vw;
  padding-bottom: 6rem;
}

.featured-hero-title {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

/* Yachts Container Content */
.featured-yachts-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  justify-content: space-evenly;
  align-items: flex-start;
}

.featured-yacht {
  height: auto;
  width: 400px;
  margin-bottom: 4rem;
}

.featured-yacht-image-1,
.featured-yacht-image-2,
.featured-yacht-image-3,
.featured-yacht-image-4 {
  width: 380px;
  height: 260px;
}

.featured-yacht-image-wrapper-1,
.featured-yacht-image-wrapper-2,
.featured-yacht-image-wrapper-3,
.featured-yacht-image-wrapper-4 {
  width: 380px;
  height: 45px;
}

.featured-yacht-description {
  margin-bottom: 2rem;
}

.featured-yacht-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Media Quieres */
@media screen and (max-width: 1440px) {
  .featured-hero {
    padding-bottom: 0rem;
  }
}

@media screen and (max-width: 428px) {
  .featured-hero-title {
    margin-bottom: 0rem;
    margin-top: 4rem;
  }
  .featured-yacht-description {
    width: 90%;
    margin-left: 5%;
  }
  .featured-yacht-image-1,
  .featured-yacht-image-2,
  .featured-yacht-image-3,
  .featured-yacht-image-4,
  .featured-yacht-image-wrapper-1,
  .featured-yacht-image-wrapper-2,
  .featured-yacht-image-wrapper-3,
  .featured-yacht-image-wrapper-4 {
    width: 100%;
  }
}
