.navbar-burger-contianer {
  width: 100vw;
  height: 100vh;
}

.navbar-burger-brand {
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.navbar-burger-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-burger-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 70vh;
}

.nav-link-burger-sm {
  white-space: nowrap;
}

.navbar-burger-models {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar-burger-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 15vh;
}

.navbar-burger-model-container-top {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
  margin-bottom: 2rem;
}

.navbar-burger-model-container-bottom {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
}

.navbar-burger-tethys-logo {
  height: 31px;
}

.navbar-burger-logo-vertical-divider {
  border: 1px solid #fff;
  transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
  height: 50px;
}

h3.nav-link-burger-lg,
h4.nav-link-burger-sm,
span.nav-link-footer {
  border-bottom: transparent 2px solid;
}

h3.nav-link-burger-lg:hover,
h4.nav-link-burger-sm:hover,
span.nav-link-footer:hover {
  border-bottom: #fff 2px solid;
}

.white.underline,
.nav-link-footer.underline {
  border-bottom: #fff 2px solid;
}

span.nav-link-footer,
span.navbar-burger-lang {
  padding-bottom: 6px;
}
