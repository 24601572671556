/* Buttons */
a.ButtonSendMeACopy,
a.ButtonGoBackOverview,
a.ButtonNextStepThree,
a.ButtonNextStepTwo,
a.ButtonNextStepOne,
a.ButtonFooter {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  box-sizing: border-box;
  text-decoration: none;
  margin-top: 16px;
  padding-bottom: 0px;
  background-color: #1e1e1e;
  color: #fff;
}

a.ButtonFooter {
  text-align: left;
  padding-left: 20px;
}

a.ButtonSendMeACopy,
a.ButtonGoBackOverview,
a.ButtonNextStepThree,
a.ButtonNextStepTwo,
a.ButtonNextStepOne {
  padding-left: 0px;
}

a.ButtonNextStepThree,
a.ButtonNextStepTwo,
a.ButtonNextStepOne {
  border: 2px solid #1e1e1e;
  margin-left: 1rem;
}

a.ButtonFooter,
a.ButtonGoBackOverview,
a.ButtonSendMeACopy {
  border: 2px solid #fff;
}

/* Hover */
a.ButtonGoBackOverview:hover,
a.ButtonNextStepOne:hover,
a.ButtonNextStepTwo:hover,
a.ButtonNextStepThree:hover,
a.ButtonSendMeACopy:hover {
  background-color: #fff;
  color: #1e1e1e;
}

/* Arrow */
img.arrow {
  width: 160px;
  margin-top: 4px;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  a.ButtonNextStepThree,
  a.ButtonNextStepTwo,
  a.ButtonNextStepOne {
    margin-left: 0rem;
  }
}
