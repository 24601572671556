.bg {
    background-color: #fff;
}

.main-1 {
    background-color: #000;
}

.main-2 {
    background-color: #F6F3F1;
}

.main-3 {
    background-color: #1e1e1e;
}

.grey {
    background-color: rgba(255, 255, 255, 0.23);
}

.error {
    background-color: #EB5757;
}

.correct {
    background-color: #219653;
}

.input {
    background-color: #2F80ED;
}

.config-grey {
    background-color: #797777;
}

.config-blue {
    background-color: #2E4A64;
}

.config-gun-metal-grey {
    background-color: #4D4949;
}

.black {
    color: #1e1e1e;
}

.white {
    color: #fff;
}  