.model-hero {
  height: 100%;
  width: 100vw;
}

.model-hero-image {
  height: 500px;
  width: auto;
}

.model-hero-description {
  width: 60%;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.model-hero-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

@media screen and (max-width: 1024px) {
  .model-hero-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .model-hero-description {
    width: 90%;
  }
}