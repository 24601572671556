.item-1-31 {
  z-index: 2;
  opacity: 1;
  background: url("../../media/img/layout-31-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-2-31 {
  background: url("../../media/img/layout-31-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-3-31 {
  background: url("../../media/img/layout-31-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

*:target ~ .item-1-31 {
  opacity: 0;
}

#item-1-31:target ~ .item-1-31 {
  opacity: 1;
}

#item-2-31:target ~ .item-2-31,
#item-3-31:target ~ .item-3-31 {
  z-index: 3;
  opacity: 1;
}
