.builderstage-categories {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.builderstage-category-inactive {
  list-style: none;
  opacity: 23%;
}

.builderstage-category-active {
  list-style: none;
  color: #1e1e1e;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .builderstage-categories {
    display: none;
  }
}
