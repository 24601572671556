.specs-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specs-lists-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 80vw;
  margin-bottom: 6rem;
}

.specs-list-a {
  margin-right: 2rem;
  width: 600px;
  height: auto;
}

.specs-list-b {
  margin-left: 2rem;
  width: 600px;
  height: auto;
}

.spec-list {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 600px;
  border-bottom: 1px solid #1e1e1e;
}

.specs-list-row {
  height: 44px;
  width: 600px;
  border-bottom: 1px solid #1e1e1e;
}

.specs-list-left {
  text-align: left !important;
  border-bottom: 1px solid #1e1e1e;
  width: 300px;
  white-space: nowrap;
}

.specs-list-right {
  text-align: right !important;
  border-bottom: 1px solid #1e1e1e;
  width: 300px;
  white-space: nowrap;
}

/* Media Quieries */
@media screen and (max-width: 900px) {
  .specs-lists-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    margin-bottom: 6rem;
    overflow: hidden;
  }
  .specs-list-a {
    margin-right: 0rem;
    width: 100%;
    height: auto;
  }
  .specs-list-b {
    margin-left: 0rem;
    width: 100%;
    height: auto;
  }
  .specs-list-row {
    width: 50%;
  }
  .specs-list-left {
    width: 100%;
    font-size: 80% !important;
  }
  .specs-list-right {
    width: 100%;
    font-size: 80% !important;
  }
  .spec-list {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    border-bottom: 1px solid #1e1e1e;
  }
}
