.about-page-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-page-hero-title {
  margin-bottom: 5rem;
}

.about-page-hero-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
  margin-left: 22rem;
}

.about-page-paragraph-1 {
  text-align: left !important;
  width: 390px;
  height: auto;
  margin-right: 5rem;
}

.about-page-paragraph-2 {
  text-align: left !important;
  width: 390px;
  height: auto;
}

/* Media Quieres */
@media screen and (max-width: 1200px) {
  .about-page-hero-title {
    margin-bottom: 2rem;
  }
  .about-page-hero-container {
    margin-bottom: 3rem;
    margin-left: 3rem;
    margin-right: 1rem;
    margin-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .about-page-hero-title {
    margin-bottom: 0rem;
  }
  .about-page-hero-container {
    margin-bottom: 3rem;
    margin-left: 0rem;
    margin-top: 2rem;
    justify-content: space-evenly;
  }
  .about-page-paragraph-1 {
    width: 40%;
    margin-right: 0rem;
  }
  .about-page-paragraph-2 {
    width: 40%;
  }
  .about-page-paragraph-1,
  .about-page-paragraph-2 {
    text-align: center !important;
  }
}

@media screen and (max-width: 428px) {
  .about-page-hero-container {
    margin-bottom: 3rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .about-page-paragraph-1,
  .about-page-paragraph-2 {
    width: 80%;
    margin-right: 0rem;
  }
  .about-page-hero-title {
    margin-bottom: 0rem;
    margin-top: 4rem;
  }
}
