/* Build */
.build-hero {
  height: 80vh;
  width: 100vw;
  margin-top: 4rem;
}

.build-image {
  position: relative;
  width: auto;
  height: 85%;
  left: 10%;
  top: 0%;
}

.build-card {
  position: relative;
  width: 40%;
  height: 60%;
  left: 55%;
  top: -78%;
}

/* Build Card Content */
.build-card-title {
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 0%;
  position: relative;
  text-align: left !important;
}

.build-card-subtitle {
  padding-left: 5%;
  padding-right: 10%;
  padding-top: 0%;
  padding-bottom: 5%;
  width: 50%;
  position: relative;
  text-align: left !important;
}

.ButtonBuild {
  margin-left: 5%;
}

/* Media Quieres */
@media screen and (max-width: 1200px) {
  .build-hero {
    height: 100%;
    width: 100vw;
  }
  .build-image {
    position: relative;
    width: 100%;
    height: auto;
    left: 0%;
    top: 0%;
  }
  .build-card {
    position: relative;
    width: 100%;
    height: 60%;
    left: 0%;
    top: 0%;
    padding-bottom: 10%;
    margin-bottom: 10%;
    margin-top: 0%;
  }
}
