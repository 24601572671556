.coming-soon-container {
  width: 100vw;
  height: 100vh;
}

.coming-soon-title {
  width: 90%;
  margin-left: 5%;
}

.coming-soon-subtitle {
  margin-bottom: 2rem;
  width: 90%;
  margin-left: 5%;
}

.coming-soon-button-container {
  width: 40vw;
  height: 10vw;
  margin-left: 30vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.coming-soon-img-contianer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.coming-soon-img {
  width: 20vw;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .coming-soon-img {
    width: 60vw;
  }
  .coming-soon-button-container {
    width: 100vw;
    height: 10vw;
    margin-left: 0vw;
  }
  .coming-soon-img {
    width: 40vw;
  }
}

@media screen and (max-width: 768px) {
  .coming-soon-img {
    width: 50vw;
  }
  .coming-soon-subtitle {
    margin-bottom: 5rem;
  }
  .coming-soon-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }
}

@media screen and (max-width: 500px) {
  .coming-soon-img {
    width: 60vw;
  }
}
