/* Questions */
.questions-hero {
  height: 400px;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("../../media/img/landing-questions.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 4rem;
}

/* Questions Content */
.questions-container {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  margin-left: 50%;
}

.questions-hero-title {
  width: 100%;
  text-align: left;
  color: #fff;
  padding-top: 0rem;
  margin-top: 0rem;
}

/* Media Quieres */
@media screen and (max-width: 1200px) {
  .questions-container {
    justify-content: center;
    margin-left: 0%;
  }
  .questions-hero-title {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .questions-hero {
    background-position: top left;
  }
}
