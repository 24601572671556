.overview-title {
  color: #1e1e1e;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.overview-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.overview-hero {
  width: 100vw;
  height: 75vh;
}

.overview-container-left {
  width: 40vw;
  margin-left: 10vw;
  height: 60vh;
  background: url(../../../media/img/overview-hero.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overview-container-right {
  width: 40vw;
  margin-right: 10vw;
  height: 60vh;
}

.overview-container {
  display: flex;
  flex-direction: row;
}

.overview-model {
  color: #fff;
}

.overview-section-options-table {
  width: 80%;
  height: auto;
  margin-top: 4rem;
  margin-left: 10%;
}

.overview-section-option-row {
  margin-top: 14px;
  height: 30px;
  width: 100%;
  border-bottom: #fff 1px solid;
}

.overview-section-option-title {
  text-align: right;
  color: #fff;
  float: left;
}

.overview-section-option-price {
  text-align: right;
  color: #fff;
  float: right;
}

.overview-section-total-price {
  text-align: right;
  color: #fff;
  margin-right: 10%;
  margin-top: 14px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) {
  .overview-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .overview-hero {
    height: 100%;
  }
  .overview-container {
    display: flex;
    flex-direction: column;
  }
  .overview-container-left {
    width: 100vw;
    margin-left: 0vw;
    height: 30vh;
  }
  .overview-container-right {
    width: 100vw;
    margin-right: 0vw;
    height: 50vh;
  }
  .overview-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  .overview-title {
    margin-bottom: 1rem;
  }
  .overview-section-option-title,
  .overview-section-option-price {
    font-size: 80% !important;
  }
}

