/* About */
.about-hero {
  height: 70vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* About Content */
.about-title {
  width: 40%;
  margin-bottom: 2rem;
}

.about-subtitle {
  width: 40%;
  margin-bottom: 3rem;
}

.about-photo-1 {
  position: absolute;
  width: 400px;
  height: auto;
  left: 0px;
  top: 10%;
}

.about-photo-2 {
  position: absolute;
  width: 400px;
  height: auto;
  right: 0px;
  bottom: 10%;
}

.about-photo-3 {
  display: none;
}

.about-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Media Quieres */
@media screen and (max-width: 429px) {
  .about-hero {
    height: 100%;
  }
  .about-title {
    width: 90%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-subtitle {
    width: 90%;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
  .about-photo-1 {
    position: relative;
    width: 100vw;
    height: auto;
  }
  .about-photo-2 {
    display: none;
  }
  .about-photo-3 {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;
    margin-top: 4rem;
    margin-bottom: 0rem;
  }
}

@media screen and (min-width: 430px) and (max-width: 1400px) {
  .about-hero {
    height: 100%;
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
  .about-title {
    width: 90%;
    margin-bottom: 2rem;
  }
  .about-subtitle {
    width: 90%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-img-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .about-photo-1 {
    position: relative;
    width: 40%;
    height: auto;
  }
  .about-photo-2 {
    position: relative;
    width: 40%;
    height: auto;
  }
  .about-photo-3 {
    display: none;
  }
}
