.item-1-41 {
  z-index: 2;
  opacity: 1;
  background: url("../../media/img/layout-41-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-2-41 {
  background: url("../../media/img/layout-41-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-3-41 {
  background: url("../../media/img/layout-41-3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-4-41 {
  background: url("../../media/img/layout-41-4.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-5-41 {
  background: url("../../media/img/layout-41-5.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

*:target ~ .item-1-41 {
  opacity: 0;
}

#item-1-41:target ~ .item-1-41 {
  opacity: 1;
}

#item-2-41:target ~ .item-2-41,
#item-3-41:target ~ .item-3-41,
#item-4-41:target ~ .item-4-41,
#item-5-41:target ~ .item-5-41 {
  z-index: 3;
  opacity: 1;
}
