/* Buttons */
a.ButtonAbout,
a.ButtonBuild,
a.ButtonContact,
a.ButtonHome,
a.ButtonBuildYachtModels,
a.ButtonSubmit,
a.ButtonBuild31,
a.ButtonBuild35,
a.ButtonBuild41,
a.ButtonBuild54,
a.ButtonFeatured,
a.ButtonGoBackOne,
a.ButtonGoBackTwo,
a.ButtonGoBackThree,
a.ButtonShowMore {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  box-sizing: border-box;
  margin-top: 16px;
  padding-bottom: 0px;
  text-decoration: none;
  border: 2px solid #1e1e1e;
  background-color: #fff;
  color: #1e1e1e;
}

a.ButtonAbout,
a.ButtonBuild,
a.ButtonContact,
a.ButtonHome,
a.ButtonBuildYachtModels {
  text-align: left;
  white-space: nowrap;
  padding-left: 20px;
}

a.ButtonSubmit,
a.ButtonBuild31,
a.ButtonBuild35,
a.ButtonBuild41,
a.ButtonBuild54,
a.ButtonFeatured {
  text-align: left;
  padding-left: 30px;
}

a.ButtonGoBackOne,
a.ButtonGoBackTwo,
a.ButtonGoBackThree {
  margin-right: 1rem;
}

/* Arrow */
img.arrow {
  width: 160px;
  margin-top: 4px;
}

/* Hover */
a.ButtonGoBackOne:hover,
a.ButtonGoBackTwo:hover,
a.ButtonGoBackThree:hover,
a.ButtonShowMore:hover {
  background-color: #1e1e1e;
  color: #fff;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  a.ButtonGoBackOne,
  a.ButtonGoBackTwo,
  a.ButtonGoBackThree {
    margin-right: 0rem;
  }
}
