@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap);
body {
  margin: 0;
}

/* Landing */
.landing-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(/static/media/landingherobg-desktop.94c1ee9c.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 90vh;
  left: 0px;
  top: 0px;
}

.landing-header {
  position: absolute;
  width: 100%;
  height: 101px;
  bottom: 0%;
}

/* Media Quieres */
@media screen and (max-width: 1100px) {
  .landing-header {
    height: 1px;
    bottom: 50%;
  }
  .landing-hero {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url(/static/media/landingherobg-mobile.d0b90c4b.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .landing-header {
    height: 101px;
    bottom: 50%;
  }
}

@media screen and (max-width: 428px) {
  .landing-hero {
    height: 100vh;
  }
}

.navbar-nav-link-dropdown {
  margin-top: 12px;
  margin-right: 30px;
  color: #1e1e1e;
  padding-bottom: 6px;
}

.header-nav-link-dropdown {
  margin-top: 12px;
  margin-right: 30px;
  color: #1e1e1e;
  padding-bottom: 6px;
}

.ml-1 {
  margin-left: 15px;
}

/* NavBar */
.navbar-hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80vw;
  padding-left: 10%;
  white-space: nowrap;
  padding-top: 30px;
  z-index: 999;
}

/* NavBar Brand */
.navbar-brand {
  width: 25vw;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

.navbar-tethys-logo {
  height: 31px;
  margin-top: 8px;
}

a:-webkit-any-link {
  text-decoration: none;
}

.navbar-vertical-divider {
  border: 1px solid #1e1e1e;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
}

.header-vertical-divider {
  border: 1px solid #fff;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
}

/* NavBar Start */
.navbar-start {
  width: 50vw;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

/* NavBar End */
.navbar-end {
  width: 25vw;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

.navbar-burger-lines__line1 {
  opacity: 0.7;
  border: 1px solid #1e1e1e;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 30px;
  height: 0px;
  right: 30px;
  top: 44px;
}

.navbar-burger-lines__line2 {
  opacity: 0.7;
  border: 1px solid #1e1e1e;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 20px;
  height: 0px;
  right: 30px;
  top: 51px;
}

.navbar-burger-lines__line3 {
  opacity: 0.7;
  border: 1px solid #1e1e1e;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 10px;
  height: 0px;
  right: 30px;
  top: 58px;
}

.header-burger-lines__line1 {
  opacity: 0.7;
  border: 1px solid #fff;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 30px;
  height: 0px;
  right: 30px;
  top: 44px;
}

.header-burger-lines__line2 {
  opacity: 0.7;
  border: 1px solid #fff;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 20px;
  height: 0px;
  right: 30px;
  top: 51px;
}

.header-burger-lines__line3 {
  opacity: 0.7;
  border: 1px solid #fff;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  width: 10px;
  height: 0px;
  right: 30px;
  top: 58px;
}

/* NavBar Content */
.navbar-nav-link {
  margin-right: 50px;
  margin-top: 12px;
  color: #1e1e1e;
  padding-bottom: 6px;
}

.header-nav-link {
  margin-right: 50px;
  margin-top: 12px;
  color: #fff;
  padding-bottom: 6px;
}

.navbar-nav-link:last-of-type {
  margin-right: 0px;
}

.navbar-link,
.navbar-nav-link,
.navbar-nav-link-dropdown {
  text-decoration: none;
}

.navbar-nav-link:hover,
.navbar-nav-link-dropdown:hover {
  border-bottom: #1e1e1e 2px solid;
}

.navbar-dropdown-arrow {
  border: solid #1e1e1e;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 5px;
  margin-bottom: 3px;
}

.header-nav-link:hover,
.header-nav-link-dropdown:hover {
  border-bottom: #fff 2px solid;
}

.header-dropdown-arrow {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 5px;
  margin-bottom: 3px;
}

.social-icon-container {
  margin-left: 2rem;
  position: absolute;
  top: 40px;
  right: 100px;
}

.social-icon {
  height: 30px;
  margin-right: 15px;
}

/* Media Quieries */
@media screen and (max-width: 1440px) {
  .navbar-start {
    width: 50vw;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 999;
  }
  .navbar-nav-link,
  .navbar-nav-link-dropdown {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .social-icon-container {
    margin-left: 2rem;
    position: absolute;
    top: 40px;
    right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-nav-link,
  .navbar-nav-link-dropdown {
    margin-right: 30px;
  }
}

@media screen and (min-width: 901px) {
  .navbar-end .navbar-burger-lines__line1,
  .navbar-end .navbar-burger-lines__line2,
  .navbar-end .navbar-burger-lines__line3 {
    display: none;
  }
  .navbar-end .header-burger-lines__line1,
  .navbar-end .header-burger-lines__line2,
  .navbar-end .header-burger-lines__line3 {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .navbar-brand {
    width: 100vw;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    z-index: 999;
    position: relative;
    left: 0;
  }
  .social-icon-container {
    margin-left: 2rem;
    position: absolute;
    top: 40px;
    right: 125px;
  }
  .navbar-tethys-logo {
    padding-left: 30px;
  }
  .navbar-start {
    display: none;
  }
  .navbar-end .navbar-nav-link,
  .navbar-end .navbar-nav-link-dropdown {
    display: none;
  }
  .navbar-end .header-nav-link,
  .navbar-end .header-nav-link-dropdown {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .social-icon-container {
    display: none;
  }
}
.dropdown-menu {
  background: #ccc;
  width: 120px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  z-index: 100;
  padding-left: 0px;
  margin-left: 100px;
}

.dropdown-menu li {
  background: #eee;
  cursor: pointer;
  text-decoration: none;
  color: #1e1e1e;
}

.dropdown-menu li:hover {
  background: #ccc;
  color: #1e1e1e;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #1e1e1e;
  padding: 16px;
}

.dropdown-link-header {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

.dropdown-menu-language {
  background: #ccc;
  width: 120px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  z-index: 100;
  padding-left: 0px;
  margin-left: 0px;
}

.dropdown-menu-language li {
  background: #eee;
  cursor: pointer;
  text-decoration: none;
  color: #1e1e1e;
}

.dropdown-menu-language li:hover {
  background: #ccc;
  color: #1e1e1e;
}

.dropdown-menu-language.clicked {
  display: none;
}
/* Featured */
.featured-hero {
  height: 100%;
  width: 100vw;
  padding-bottom: 6rem;
}

.featured-hero-title {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

/* Yachts Container Content */
.featured-yachts-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  justify-content: space-evenly;
  align-items: flex-start;
}

.featured-yacht {
  height: auto;
  width: 400px;
  margin-bottom: 4rem;
}

.featured-yacht-image-1,
.featured-yacht-image-2,
.featured-yacht-image-3,
.featured-yacht-image-4 {
  width: 380px;
  height: 260px;
}

.featured-yacht-image-wrapper-1,
.featured-yacht-image-wrapper-2,
.featured-yacht-image-wrapper-3,
.featured-yacht-image-wrapper-4 {
  width: 380px;
  height: 45px;
}

.featured-yacht-description {
  margin-bottom: 2rem;
}

.featured-yacht-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Media Quieres */
@media screen and (max-width: 1440px) {
  .featured-hero {
    padding-bottom: 0rem;
  }
}

@media screen and (max-width: 428px) {
  .featured-hero-title {
    margin-bottom: 0rem;
    margin-top: 4rem;
  }
  .featured-yacht-description {
    width: 90%;
    margin-left: 5%;
  }
  .featured-yacht-image-1,
  .featured-yacht-image-2,
  .featured-yacht-image-3,
  .featured-yacht-image-4,
  .featured-yacht-image-wrapper-1,
  .featured-yacht-image-wrapper-2,
  .featured-yacht-image-wrapper-3,
  .featured-yacht-image-wrapper-4 {
    width: 100%;
  }
}

/* Buttons */
a.ButtonAbout,
a.ButtonBuild,
a.ButtonContact,
a.ButtonHome,
a.ButtonBuildYachtModels,
a.ButtonSubmit,
a.ButtonBuild31,
a.ButtonBuild35,
a.ButtonBuild41,
a.ButtonBuild54,
a.ButtonFeatured,
a.ButtonGoBackOne,
a.ButtonGoBackTwo,
a.ButtonGoBackThree,
a.ButtonShowMore {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  box-sizing: border-box;
  margin-top: 16px;
  padding-bottom: 0px;
  text-decoration: none;
  border: 2px solid #1e1e1e;
  background-color: #fff;
  color: #1e1e1e;
}

a.ButtonAbout,
a.ButtonBuild,
a.ButtonContact,
a.ButtonHome,
a.ButtonBuildYachtModels {
  text-align: left;
  white-space: nowrap;
  padding-left: 20px;
}

a.ButtonSubmit,
a.ButtonBuild31,
a.ButtonBuild35,
a.ButtonBuild41,
a.ButtonBuild54,
a.ButtonFeatured {
  text-align: left;
  padding-left: 30px;
}

a.ButtonGoBackOne,
a.ButtonGoBackTwo,
a.ButtonGoBackThree {
  margin-right: 1rem;
}

/* Arrow */
img.arrow {
  width: 160px;
  margin-top: 4px;
}

/* Hover */
a.ButtonGoBackOne:hover,
a.ButtonGoBackTwo:hover,
a.ButtonGoBackThree:hover,
a.ButtonShowMore:hover {
  background-color: #1e1e1e;
  color: #fff;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  a.ButtonGoBackOne,
  a.ButtonGoBackTwo,
  a.ButtonGoBackThree {
    margin-right: 0rem;
  }
}

/* About */
.about-hero {
  height: 70vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* About Content */
.about-title {
  width: 40%;
  margin-bottom: 2rem;
}

.about-subtitle {
  width: 40%;
  margin-bottom: 3rem;
}

.about-photo-1 {
  position: absolute;
  width: 400px;
  height: auto;
  left: 0px;
  top: 10%;
}

.about-photo-2 {
  position: absolute;
  width: 400px;
  height: auto;
  right: 0px;
  bottom: 10%;
}

.about-photo-3 {
  display: none;
}

.about-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Media Quieres */
@media screen and (max-width: 429px) {
  .about-hero {
    height: 100%;
  }
  .about-title {
    width: 90%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-subtitle {
    width: 90%;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
  .about-photo-1 {
    position: relative;
    width: 100vw;
    height: auto;
  }
  .about-photo-2 {
    display: none;
  }
  .about-photo-3 {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;
    margin-top: 4rem;
    margin-bottom: 0rem;
  }
}

@media screen and (min-width: 430px) and (max-width: 1400px) {
  .about-hero {
    height: 100%;
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
  .about-title {
    width: 90%;
    margin-bottom: 2rem;
  }
  .about-subtitle {
    width: 90%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-img-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .about-photo-1 {
    position: relative;
    width: 40%;
    height: auto;
  }
  .about-photo-2 {
    position: relative;
    width: 40%;
    height: auto;
  }
  .about-photo-3 {
    display: none;
  }
}

/* Build */
.build-hero {
  height: 80vh;
  width: 100vw;
  margin-top: 4rem;
}

.build-image {
  position: relative;
  width: auto;
  height: 85%;
  left: 10%;
  top: 0%;
}

.build-card {
  position: relative;
  width: 40%;
  height: 60%;
  left: 55%;
  top: -78%;
}

/* Build Card Content */
.build-card-title {
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 0%;
  position: relative;
  text-align: left !important;
}

.build-card-subtitle {
  padding-left: 5%;
  padding-right: 10%;
  padding-top: 0%;
  padding-bottom: 5%;
  width: 50%;
  position: relative;
  text-align: left !important;
}

.ButtonBuild {
  margin-left: 5%;
}

/* Media Quieres */
@media screen and (max-width: 1200px) {
  .build-hero {
    height: 100%;
    width: 100vw;
  }
  .build-image {
    position: relative;
    width: 100%;
    height: auto;
    left: 0%;
    top: 0%;
  }
  .build-card {
    position: relative;
    width: 100%;
    height: 60%;
    left: 0%;
    top: 0%;
    padding-bottom: 10%;
    margin-bottom: 10%;
    margin-top: 0%;
  }
}

/* News */
.news-hero {
  height: 100%;
  width: 100vw;
}

.news-hero-title {
  position: relative;
  text-align: left !important;
  margin-bottom: 2rem;
  margin-top: 0rem;
  margin-left: 4rem;
}

/* News Articles */
.news-articles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 4rem;
}

.news-article {
  position: relative;
  width: 400px;
  height: auto;
  margin-bottom: 4rem;
}

/* News Articles Content */
.news-article-date {
  position: relative;
  width: 400px;
  height: 16px;
  text-align: left !important;
}

.news-article-title {
  position: relative;
  width: 100px;
  height: 16px;
  text-align: left !important;
}

.news-article-description {
  position: relative;
  width: 400px;
  height: 42px;
  text-align: left !important;
}

.news-article-image {
  width: 400px;
  height: auto;
  box-sizing: border-box;
  border: 4px solid transparent;
}

.news-article-image:hover {
  border: 4px solid #2F80ED;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .news-hero-title {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: 0rem;
  }
}

@media screen and (max-width: 768px) {
.news-hero-title {
  text-align: center !important;
}
}

@media screen and (max-width: 428px) {
  .news-article-image {
    width: 100%;
  }
  .news-article-description {
    width: 100%;
  }
  .news-article {
    width: 90%;
  }
  .news-article-date {
    width: 100%;
  }
}

/* Questions */
.questions-hero {
  height: 400px;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(/static/media/landing-questions.c54d5d9c.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 4rem;
}

/* Questions Content */
.questions-container {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  margin-left: 50%;
}

.questions-hero-title {
  width: 100%;
  text-align: left;
  color: #fff;
  padding-top: 0rem;
  margin-top: 0rem;
}

/* Media Quieres */
@media screen and (max-width: 1200px) {
  .questions-container {
    justify-content: center;
    margin-left: 0%;
  }
  .questions-hero-title {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .questions-hero {
    background-position: top left;
  }
}

/* Dealer*/
.dealer-hero {
  width: 100vw;
  height: 100vh;
  position: relative;
}

/* Images */
.photo-1 {
  position: absolute;
  width: 390px;
  height: 225px;
  top: 0%;
  left: 0%;
}

.photo-2 {
  position: absolute;
  width: 345px;
  height: 225px;
  top: 36%;
  left: 7%;
}

.photo-3 {
  position: absolute;
  width: 315px;
  height: 225px;
  top: 70%;
  left: 0%;
}

.photo-4 {
  position: absolute;
  width: 390px;
  height: 225px;
  top: 70%;
  right: 5%;
}

.photo-5 {
  position: absolute;
  width: 315px;
  height: 225px;
  top: 36%;
  right: 0%;
}

.photo-6 {
  position: absolute;
  width: 390px;
  height: 225px;
  top: 0%;
  right: 7%;
}

.photo-7 {
  display: none;
}

/* Dealer Content*/
.dealer-content {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dealer-title {
  width: 100%;
  margin-top: 0rem;
}

.dealer-subtitle {
  width: 30%;
  margin-bottom: 4rem;
}

.dealer-dropdown {
  width: 20%;
  font-family: "Work Sans", sans-serif;
}

/* Media Quieres */
@media screen and (max-width: 1340px) {
  .dealer-content {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }
  .dealer-hero {
    width: 100vw;
    height: auto;
  }
  .photo-1,
  .photo-2,
  .photo-3,
  .photo-4,
  .photo-5,
  .photo-6 {
    display: none;
  }
  .dealer-subtitle {
    width: 70%;
  }
  .dealer-dropdown {
    width: 50%;
  }
  .photo-7 {
    margin-top: 4rem;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    width: 100%;
    height: auto;
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .dealer-subtitle {
    width: 80%;
  }
}

@media screen and (max-width: 428px) {
  .dealer-dropdown {
    width: 80%;
  }
  .dealer-title {
    margin-top: 4rem;
  }
}

/* Footer */
.footer-top {
  height: 350px;
  display: flex;
  flex-direction: row;
}

/* Footer Brand */
.footer-brand {
  width: 30vw;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  height: 50px;
}

.footer-logo-vertical-divider {
  border: 1px solid #fff;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
}

.footer-tethys-logo {
  height: 31px;
  margin-top: 8px;
}

/* Footer Categories */
.footer-categories {
  display: flex;
  flex-direction: row;
}

/* Footer Models */
.footer-category-models {
  width: 20vw;
  padding-top: 50px;
}

/* Footer Contact */
.footer-category-contact {
  width: 25vw;
  padding-top: 50px;
}

/* Footer Build */
.footer-category-build {
  width: 25vw;
  padding-top: 50px;
}

/* Footer Category Content */
.footer-category-list {
  list-style: none;
  color: #fff;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  max-width: 100%;
}

.footer-category-list__item {
  margin-top: 15px;
}

.footer-category-list__item:first-child {
  margin-top: 30px;
}

button.footer-span-item {
  border: none;
  color: #fff;
  padding-left: 0px;
  padding-bottom: 6px;
  border-bottom: transparent 2px solid;
}

span.footer-span-item {
  padding-bottom: 6px;
}

button.footer-span-item:hover {
  border-bottom: #fff 2px solid;
}

span.footer-span-item:hover {
  border-bottom: #fff 2px solid;
}

.footer-desc {
  width: 200px;
}

/* Copyright */
.footer-copyright {
  height: 80px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer-copyright-text {
  color: #fff;
  font-size: 10px;
  margin-bottom: 2rem;
}

.footer-social-icon {
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
}

/* Tooltip */
.tooltip-container {
  position: relative;
}

.tooltip-box {
  position: absolute;
  background: #2F80ED;
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  top: -5px;
  left: 110%;
  display: none;
  width: 100px;
  padding: 5px 0;
  z-index: 1;
}

.tooltip-box.visible {
  display: block;
}

.tooltip-arrow {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #2F80ED transparent transparent;
}


/* Media Quieres */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .footer-top {
    flex-direction: column;
    height: auto;
    padding-bottom: 40px;
  }
  .footer-brand {
    width: 100vw;
    padding-left: 0px;
  }
  .footer-categories {
    display: flex;
    flex-direction: row;
  }
  .footer-category-models {
    width: 33vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .footer-category-contact {
    width: 34vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }
  .footer-category-build {
    width: 33vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: center;
    margin-bottom: 50px;
  }
  .footer-category-list__item:first-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    height: auto;
  }
  .footer-brand {
    width: 100vw;
    padding-left: 0px;
  }
  .footer-categories {
    display: flex;
    flex-direction: column;
  }
  .footer-category-models {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    order: 2;
    padding-top: 0px;
  }
  .footer-category-contact {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    order: 2;
    margin-bottom: 4rem;
    text-align: center !important;
  }
  .footer-category-build {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    text-align: center !important;
  }
  .footer-category-list__item:first-child {
    margin-top: 0px;
  }
  .footer-category-models .footer-category-list__item {
    display: inline;
    margin-right: 30px;
    margin-top: 15px;
  }
  .footer-category-models .footer-category-list__item:last-of-type {
    display: inline;
    margin-right: 0px;
  }
  .footer-desc {
    width: 200px;
    margin: auto;
  }
}

/* Buttons */
a.ButtonSendMeACopy,
a.ButtonGoBackOverview,
a.ButtonNextStepThree,
a.ButtonNextStepTwo,
a.ButtonNextStepOne,
a.ButtonFooter {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  box-sizing: border-box;
  text-decoration: none;
  margin-top: 16px;
  padding-bottom: 0px;
  background-color: #1e1e1e;
  color: #fff;
}

a.ButtonFooter {
  text-align: left;
  padding-left: 20px;
}

a.ButtonSendMeACopy,
a.ButtonGoBackOverview,
a.ButtonNextStepThree,
a.ButtonNextStepTwo,
a.ButtonNextStepOne {
  padding-left: 0px;
}

a.ButtonNextStepThree,
a.ButtonNextStepTwo,
a.ButtonNextStepOne {
  border: 2px solid #1e1e1e;
  margin-left: 1rem;
}

a.ButtonFooter,
a.ButtonGoBackOverview,
a.ButtonSendMeACopy {
  border: 2px solid #fff;
}

/* Hover */
a.ButtonGoBackOverview:hover,
a.ButtonNextStepOne:hover,
a.ButtonNextStepTwo:hover,
a.ButtonNextStepThree:hover,
a.ButtonSendMeACopy:hover {
  background-color: #fff;
  color: #1e1e1e;
}

/* Arrow */
img.arrow {
  width: 160px;
  margin-top: 4px;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  a.ButtonNextStepThree,
  a.ButtonNextStepTwo,
  a.ButtonNextStepOne {
    margin-left: 0rem;
  }
}

.navbar-burger-contianer {
  width: 100vw;
  height: 100vh;
}

.navbar-burger-brand {
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.navbar-burger-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-burger-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 70vh;
}

.nav-link-burger-sm {
  white-space: nowrap;
}

.navbar-burger-models {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar-burger-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 15vh;
}

.navbar-burger-model-container-top {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
  margin-bottom: 2rem;
}

.navbar-burger-model-container-bottom {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
}

.navbar-burger-tethys-logo {
  height: 31px;
}

.navbar-burger-logo-vertical-divider {
  border: 1px solid #fff;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 15px;
  margin-right: 15px;
  height: 50px;
}

h3.nav-link-burger-lg,
h4.nav-link-burger-sm,
span.nav-link-footer {
  border-bottom: transparent 2px solid;
}

h3.nav-link-burger-lg:hover,
h4.nav-link-burger-sm:hover,
span.nav-link-footer:hover {
  border-bottom: #fff 2px solid;
}

.white.underline,
.nav-link-footer.underline {
  border-bottom: #fff 2px solid;
}

span.nav-link-footer,
span.navbar-burger-lang {
  padding-bottom: 6px;
}

.about-page-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-page-hero-title {
  margin-bottom: 5rem;
}

.about-page-hero-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
  margin-left: 22rem;
}

.about-page-paragraph-1 {
  text-align: left !important;
  width: 390px;
  height: auto;
  margin-right: 5rem;
}

.about-page-paragraph-2 {
  text-align: left !important;
  width: 390px;
  height: auto;
}

/* Media Quieres */
@media screen and (max-width: 1200px) {
  .about-page-hero-title {
    margin-bottom: 2rem;
  }
  .about-page-hero-container {
    margin-bottom: 3rem;
    margin-left: 3rem;
    margin-right: 1rem;
    margin-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .about-page-hero-title {
    margin-bottom: 0rem;
  }
  .about-page-hero-container {
    margin-bottom: 3rem;
    margin-left: 0rem;
    margin-top: 2rem;
    justify-content: space-evenly;
  }
  .about-page-paragraph-1 {
    width: 40%;
    margin-right: 0rem;
  }
  .about-page-paragraph-2 {
    width: 40%;
  }
  .about-page-paragraph-1,
  .about-page-paragraph-2 {
    text-align: center !important;
  }
}

@media screen and (max-width: 428px) {
  .about-page-hero-container {
    margin-bottom: 3rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .about-page-paragraph-1,
  .about-page-paragraph-2 {
    width: 80%;
    margin-right: 0rem;
  }
  .about-page-hero-title {
    margin-bottom: 0rem;
    margin-top: 4rem;
  }
}

/* Containers */
.about-container {
  width: 100vw;
}

.about-ce-container,
.about-research-container,
.about-abyc-container,
.about-persona-1-container,
.about-persona-2-container,
.about-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 8rem;
  margin-bottom: 8rem;
  align-items: center;
  height: 100%;
}

.about-ce-content-container,
.about-intro-hero,
.about-abyc-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.about-persona-1-content-container,
.about-persona-2-content-container,
.about-info-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* Text */
.about-intro-quote {
  width: 60%;
  margin-left: 20%;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.about-abyc-title,
.about-persona-1-title,
.about-persona-2-title,
.about-ce-title {
  line-height: 0px;
}

.about-research-title {
  text-align: right !important;
}

.about-ce-title,
.about-persona-1-title,
.about-persona-2-title,
.about-persona-1-paragraph,
.about-abyc-paragraph,
.about-ce-paragraph,
.about-persona-2-paragraph,
.about-research-paragraph,
.about-info-paragraph {
  text-align: left !important;
  width: 390px;
  height: auto;
}

/* Images */
.about-abyc-image {
  width: auto;
  height: 360px;
}

.about-ce-image {
  width: 840px;
  height: 400px;
}

.about-ce-logo {
  height: 60px;
  width: auto;
  margin-left: 1rem;
}

.about-intro-image {
  width: 90%;
  height: auto;
  margin-left: 10%;
  margin-bottom: 5rem;
}

.about-research-image {
  width: 315px;
  height: 315px;
  margin-right: 4rem;
  margin-left: 4rem;
}

.about-persona-1-image,
.about-persona-2-image {
  height: 465px;
  width: auto;
}

.about-info-image-1,
.about-info-image-2 {
  width: 600px;
  height: auto;
}

.about-info-image-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

strong {
  font-size: 125% !important;
}

/* Media Quieres */
@media screen and (max-width: 1300px) {
  .about-ce-image {
    width: 50%;
    height: auto;
  }
  .about-research-image {
    width: 25%;
    height: auto;
  }
  .about-research-title,
  .about-research-paragraph {
    width: 300px;
    height: auto;
  }
  .about-abyc-image {
    width: 40%;
    height: auto;
  }
  .about-ce-container,
  .about-abyc-container,
  .about-research-container {
    margin-bottom: 5rem;
  }
  .about-info-image-1,
  .about-info-image-2 {
    width: 400px;
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .about-persona-1-image,
  .about-persona-2-image {
    width: 300px;
    height: auto;
  }
  .about-persona-1-container,
  .about-persona-2-container,
  .about-info-container {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 950px) {
  .about-ce-container,
  .about-abyc-container,
  .about-research-container {
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    height: 100%;
    margin-top: 2rem;
  }
  .about-ce-image {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
  .about-research-image,
  .about-abyc-image {
    width: 40%;
    height: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-ce-paragraph,
  .about-ce-title,
  .about-abyc-paragraph,
  .about-abyc-title {
    width: 90%;
    margin-left: 5%;
  }
  .about-research-paragraph,
  .about-research-title {
    width: 90%;
    order: 2;
  }
  .about-ce-logo {
    height: 40px;
  }
  .about-ce-title,
  .about-abyc-title,
  .about-research-title,
  .about-ce-paragraph,
  .about-ce-title,
  .about-abyc-paragraph,
  .about-abyc-title,
  .about-research-paragraph {
    text-align: center !important;
  }
}

@media screen and (max-width: 850px) {
  .about-persona-1-container,
  .about-persona-2-container,
  .about-info-container {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .about-persona-1-paragraph,
  .about-persona-1-title,
  .about-persona-2-paragraph,
  .about-persona-2-title,
  .about-info-paragraph {
    width: 90%;
    margin-left: 5%;
  }
  .about-persona-2-content-container,
  .about-info-paragraph {
    order: 2;
  }
  .about-persona-1-image,
  .about-persona-2-image {
    width: 40%;
    height: auto;
    margin-bottom: 2rem;
  }
  .about-info-image-2 {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 100%;
    height: auto;
  }
  .about-info-image-1 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .about-intro-quote {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 0rem;
    margin-top: 3.5rem;
  }
  .about-intro-image {
    width: 100%;
    margin-left: 0rem;
  }
  .about-persona-2-paragraph,
  .about-persona-1-paragraph,
  .about-persona-1-title,
  .about-persona-2-title,
  .about-info-paragraph {
    text-align: center !important;
  }
}

@media screen and (max-width: 450px) {
  .about-ce-image {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .about-research-image,
  .about-abyc-image {
    width: 40%;
    height: auto;
  }
}

@media screen and (max-width: 428px) {
  .about-persona-1-image,
  .about-persona-2-image,
  .about-info-image-1,
  .about-info-image-2 {
    width: 100%;
    height: auto;
  }
  .about-persona-1-title,
  .about-persona-2-title {
    margin-top: 6rem;
  }
  .about-persona-2-paragraph {
    margin-bottom: 2rem;
  }
  .about-persona-2-content-container {
    order: 2;
  }
  .about-info-image-1,
  .about-info-image-2 {
    margin-top: 0rem;
    margin-bottom: 4rem;
  }
}

video {
  object-fit: cover;
}

.contact-hero {
  height: 100%;
  width: 100vw;
  position: relative;
}

.contact-hero-title {
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.slider-image {
  height: 370px;
  width: 100%;
}

.overflow-remove {
  overflow-x: hidden;
}

/* Media Quieres */
@media screen and (max-width: 768px) {
  .slider-image {
    height: 300px;
    width: 100%;
    margin-bottom: 3rem;
  }
}
.contact-form-instructions {
  color: #1e1e1e;
  margin-bottom: 4rem;
  margin-top: 4rem;
  width: 40%;
  margin-left: 30%;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
}

.contact-form-input {
  width: 635px;
  height: 50px;
  margin-bottom: 1rem;
  border-left: none;
  border-right: none;
  border-top: 0.75px solid rgb(0, 0, 0, 0.43);
  border-bottom: 0.75px solid rgb(0, 0, 0, 0.43);
  color: rgb(0, 0, 0, 0.23);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus {
  border-top: 0.75px solid #2f80ed;
  border-bottom: 0.75px solid #2f80ed;
  border-left: none;
  border-right: none;
  color: #1e1e1e;
}

/* input[type=text]:invalid,
  input[type=tel]:invalid,
  input[type=email]:invalid {
    border-top: 0.75px solid #EB5757;
    border-bottom: 0.75px solid #EB5757;
    border-left: none;
    border-right: none;
    color: #1e1e1e;
    background: url(../media/img/error.svg) no-repeat right center;
    background-position-x: 98%;
  } */

input[type="text"]:valid,
input[type="tel"]:valid,
input[type="email"]:valid {
  border-top: 0.75px solid #219653;
  border-bottom: 0.75px solid #219653;
  border-left: none;
  border-right: none;
  color: #1e1e1e;
  background: url(/static/media/checkmark.df09494d.svg) no-repeat right center;
  background-position-x: 98%;
}

/* Media Quieres */
@media screen and (max-width: 1400px) {
  .contact-form-instructions {
    width: 70%;
    margin-left: 15%;
  }
}

@media screen and (max-width: 768px) {
  .contact-form-instructions {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (max-width: 650px) {
  .contact-form-input {
    width: 80%;
  }
}

/* Featured */
.yacht-selector-hero {
  height: 100%;
  width: 100vw;
  padding-bottom: 6rem;
}

.yacht-selector-hero-title {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

/* Yachts Container Content */
.yacht-selector-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  justify-content: space-evenly;
  align-items: flex-start;
}

.yacht-selector {
  height: auto;
  width: 285px;
  margin-bottom: 4rem;
}

.yacht-selector-image-1,
.yacht-selector-image-2,
.yacht-selector-image-3,
.yacht-selector-image-4 {
  width: 285px;
  height: 195px;
}

.yacht-selector-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Media Quieres */
@media screen and (max-width: 1440px) {
  .yacht-selector-hero {
    padding-bottom: 0rem;
  }
}

@media screen and (max-width: 428px) {
  .yacht-selector-hero-title {
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
}

.model-hero {
  height: 100%;
  width: 100vw;
}

.model-hero-image {
  height: 500px;
  width: auto;
}

.model-hero-description {
  width: 60%;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.model-hero-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

@media screen and (max-width: 1024px) {
  .model-hero-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .model-hero-description {
    width: 90%;
  }
}
.specs-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specs-lists-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 80vw;
  margin-bottom: 6rem;
}

.specs-list-a {
  margin-right: 2rem;
  width: 600px;
  height: auto;
}

.specs-list-b {
  margin-left: 2rem;
  width: 600px;
  height: auto;
}

.spec-list {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 600px;
  border-bottom: 1px solid #1e1e1e;
}

.specs-list-row {
  height: 44px;
  width: 600px;
  border-bottom: 1px solid #1e1e1e;
}

.specs-list-left {
  text-align: left !important;
  border-bottom: 1px solid #1e1e1e;
  width: 300px;
  white-space: nowrap;
}

.specs-list-right {
  text-align: right !important;
  border-bottom: 1px solid #1e1e1e;
  width: 300px;
  white-space: nowrap;
}

/* Media Quieries */
@media screen and (max-width: 900px) {
  .specs-lists-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    margin-bottom: 6rem;
    overflow: hidden;
  }
  .specs-list-a {
    margin-right: 0rem;
    width: 100%;
    height: auto;
  }
  .specs-list-b {
    margin-left: 0rem;
    width: 100%;
    height: auto;
  }
  .specs-list-row {
    width: 50%;
  }
  .specs-list-left {
    width: 100%;
    font-size: 80% !important;
  }
  .specs-list-right {
    width: 100%;
    font-size: 80% !important;
  }
  .spec-list {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    border-bottom: 1px solid #1e1e1e;
  }
}

.carousel-wrapper {
  height: 50vh;
  position: relative;
  width: 50vw;
  margin: 0rem auto 3rem auto;
}

.carousel-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 50px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.arrow-carousel {
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 12px;
}

.arrow-prev {
  left: -10%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(135deg);
          transform: translateY(-50%) rotate(135deg);
}

.arrow-next {
  right: -10%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
}

.light {
  color: white;
}

[id^="item"] {
  display: none;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .carousel-wrapper {
    height: 40vh;
    position: relative;
    width: 70vw;
    margin: 0rem auto 2rem auto;
  }
}

@media screen and (max-width: 900px) {
  .carousel-wrapper {
    height: 30vh;
    position: relative;
    width: 75vw;
    margin: 0rem auto 0rem auto;
  }
}

@media (max-width: 480px) {
  .arrow-carousel,
  .light .arrow-carousel {
    background-size: 10px;
    background-position: 10px 50%;
  }
}

.item-1-31 {
  z-index: 2;
  opacity: 1;
  background: url(/static/media/layout-31-1.56971e33.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-2-31 {
  background: url(/static/media/layout-31-2.5bb9b6e6.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-3-31 {
  background: url(/static/media/layout-31-1.56971e33.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

*:target ~ .item-1-31 {
  opacity: 0;
}

#item-1-31:target ~ .item-1-31 {
  opacity: 1;
}

#item-2-31:target ~ .item-2-31,
#item-3-31:target ~ .item-3-31 {
  z-index: 3;
  opacity: 1;
}

.model-gallery-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 6rem;
  height: 100%;
}

.model-gallery-hero-title {
  margin-top: 6rem;
}

.model-gallery-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 505px;
  width: auto;
}

.model-gallery-image-side {
  height: 155px;
  width: 215px;
}

.model-gallery-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 760px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.model-gallery-image-center {
  height: 505px;
  width: 760px;
}

@media screen and (max-width: 1200px) {
  .model-gallery-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .model-gallery-image-side {
    height: 248px;
    width: 344px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .model-gallery-center {
    margin-bottom: 1.5rem;
    height: 100%;
    width: 100%;
    order: 0;
  }
  .model-gallery-side {
    height: 100%;
    order: 1;
  }
  .model-gallery-image-side {
    margin-bottom: 1.5rem;
  }
  .model-gallery-image-center {
    height: auto;
    width: 72%;
  }
}

@media screen and (max-width: 1024px) {
  .model-gallery-center {
    order: 2;
  }
  .model-gallery-image-center {
    height: 248px;
    width: 344px;
  }
}

/* @media screen and (max-width: 428px) {
  .model-gallery-container {
    flex-direction: column;
  }
} */

.buildyachtmodels-hero {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  margin-bottom: 8rem;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.buildyachtmodels-image-1 {
  position: relative;
  height: 525px;
  width: auto;
  z-index: 1;
  left: 50px;
}

.buildyachtmodels-card {
  position: relative;
  width: 600px;
  height: 340px;
  z-index: 2;
  padding: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.buildyachtmodels-card-title {
  text-align: left !important;
  width: 100%;
}

.buildyachtmodels-card-subtitle {
  text-align: left !important;
  margin-bottom: 2rem;
  width: 80%;
}

.buildyachtmodels-image-2 {
  position: relative;
  height: 525px;
  width: auto;
  z-index: 1;
  right: 50px;
}

.ButtonBuildYachtModels {
  margin-left: 0%;
}

/* Media Quieres */
@media screen and (max-width: 1418px) {
  .buildyachtmodels-image-1 {
    left: 0px;
  }
  .buildyachtmodels-image-2 {
    display: none;
  }
  .buildyachtmodels-image-1 {
    width: 90%;
    height: auto;
    order: 1;
  }
  .buildyachtmodels-hero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0px;
  }
  .buildyachtmodels-card {
    width: 70%;
    height: auto;
    right: 0px;
    padding: 20px;
    padding-bottom: 40px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .buildyachtmodels-card-title,
  .buildyachtmodels-card-subtitle {
    text-align: center !important;
  }
}

@media screen and (max-width: 428px) {
  .buildyachtmodels-card {
    padding-bottom: 40px;
  }
}

.item-1-35 {
  z-index: 2;
  opacity: 1;
  background: url(/static/media/layout-35-1.f089f6c8.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-2-35 {
  background: url(/static/media/layout-35-2.f26bb39c.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-3-35 {
  background: url(/static/media/layout-35-3.1e1fe595.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

*:target ~ .item-1-35 {
  opacity: 0;
}

#item-1-35:target ~ .item-1-35 {
  opacity: 1;
}

#item-2-35:target ~ .item-2-35,
#item-3-35:target ~ .item-3-35 {
  z-index: 3;
  opacity: 1;
}

.item-1-41 {
  z-index: 2;
  opacity: 1;
  background: url(/static/media/layout-41-1.8d3fcb92.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-2-41 {
  background: url(/static/media/layout-41-2.4112e59e.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-3-41 {
  background: url(/static/media/layout-41-3.dcff601b.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-4-41 {
  background: url(/static/media/layout-41-4.a19caf07.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-5-41 {
  background: url(/static/media/layout-41-5.750fec03.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

*:target ~ .item-1-41 {
  opacity: 0;
}

#item-1-41:target ~ .item-1-41 {
  opacity: 1;
}

#item-2-41:target ~ .item-2-41,
#item-3-41:target ~ .item-3-41,
#item-4-41:target ~ .item-4-41,
#item-5-41:target ~ .item-5-41 {
  z-index: 3;
  opacity: 1;
}

.item-1-54 {
  z-index: 2;
  opacity: 1;
  background: url(/static/media/layout-54-1.ca61f994.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-2-54 {
  background: url(/static/media/layout-54-2.25702e70.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-3-54 {
  background: url(/static/media/layout-54-3.b87998eb.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-4-54 {
  background: url(/static/media/layout-54-4.939435d0.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.item-5-54 {
  background: url(/static/media/layout-54-5.75b6f2ac.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

*:target ~ .item-1-54 {
  opacity: 0;
}

#item-1-54:target ~ .item-1-54 {
  opacity: 1;
}

#item-2-54:target ~ .item-2-54,
#item-3-54:target ~ .item-3-54,
#item-4:target ~ .item-4-54,
#item-5-54:target ~ .item-5-54 {
  z-index: 3;
  opacity: 1;
}

.builderstage-categories {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.builderstage-category-inactive {
  list-style: none;
  opacity: 23%;
}

.builderstage-category-active {
  list-style: none;
  color: #1e1e1e;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .builderstage-categories {
    display: none;
  }
}

.external-paint-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
  height: 80vh;
  width: 60vw;
}

.external-paint-colors {
  width: 60vw;
  height: 25vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.external-paint-price {
  margin-bottom: 1rem;
}

.external-paint-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 2px solid transparent;
}

.external-paint-color:hover {
  border: 2px solid #1e1e1e;
}

.external-paint-color-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid #1e1e1e;
  margin-top: 1rem;
}

.external-paint-buttons-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.external-paint-title-adaptive {
  display: none;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .external-paint-container {
    width: 100vw;
    height: 40%;
    margin-bottom: 2rem;
  }
  .external-paint-colors {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .external-paint-buttons-container {
    position: relative;
    width: 100vw;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0rem;
  }
  .external-paint-color {
    width: 40%;
    margin-bottom: 2rem;
  }
  .external-paint-title-adaptive {
    display: block;
    margin-bottom: 2rem;
  }
}

.overview-preview-top {
  width: 100%;
  height: 30vh;
  background: url(/static/media/overview-preview.285ffafb.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overview-preview-bottom {
  width: 100%;
  height: 40vh;
}

.overview-preview-container {
  width: 40vw;
  height: 70vh;
  position: absolute;
  top: 25%;
  right: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.overview-preview-options-table {
  width: 75%;
  height: auto;
  margin-top: 4rem;
  margin-left: 10%;
}

.overview-preview-option-row {
  margin-top: 14px;
  height: 30px;
  width: 100%;
  border-bottom: #fff 1px solid;
}

.overview-preview-option-title {
  text-align: right;
  color: #fff;
  float: left;
  white-space: wrap;
}

.overview-preview-option-price {
  text-align: right;
  color: #fff;
  float: right;
}

.overview-preview-total-price {
  text-align: right;
  color: #fff;
  margin-right: 15%;
  margin-top: 14px;
}

@media screen and (max-width: 1024px) {
  .overview-preview-top {
    width: 100%;
    height: 30vh;
  }
  .overview-preview-bottom {
    width: 100%;
    height: 40vh;
  }
  .overview-preview-container {
    width: 100vw;
    height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .overview-preview-options-table {
    width: 100%;
    height: auto;
    margin-top: 4rem;
    margin-left: 0%;
  }
  .overview-preview-option-row {
    width: 80%;
    margin-left: 10%;
  }
  .overview-preview-total-price {
    margin-right: 10%;
  }
  .overview-preview-option-title,
  .overview-preview-option-price {
    font-size: 80% !important;
  }
}

@media screen and (max-width: 768px) {
  .overview-preview-top {
    height: 20vh;
  }
  .overview-preview-bottom {
    height: 30vh;
  }
  .overview-preview-container {
    height: 50vh;
  }
}

.engine-options-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
  height: 90vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
}

.engine-options-buttons-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.engine-options-step-title {
  color: #1e1e1e;
  margin-left: 5vw;
  text-align: left !important;
}

.fuel-type-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 6vw;
}

.fuel-type-option {
  width: 115px;
  height: 60px;
  border: 2px solid #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5%;
}

.fuel-type-option:hover {
  background-color: #1e1e1e;
}

.fuel-type-option:hover .fuel-type-title {
  color: #fff;
}

.fuel-type-title {
  color: #1e1e1e;
}

.engine-type-table {
  width: 80%;
  margin-left: 10%;
}

.engine-type-table-row {
  width: 100%;
  height: 50px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  border: 1px solid #1e1e1e;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.engine-type-table-row:hover .engine-type-table-text {
  color: #fff;
}

.engine-type-table-row:hover {
  background-color: #1e1e1e;
  border: 1px solid #1e1e1e;
}

.engine-type-table-text {
  color: #1e1e1e;
  margin-right: 10px;
  width: 80%;
}

.model {
  width: 50%;
  text-align: left;
  margin-left: 1rem;
}

.price {
  width: 50%;
  text-align: right;
  margin-right: 1rem;
}

.engine-options-title-adaptive {
  display: none;
}

.step-banner {
  display: none;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .engine-options-container {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .engine-options-buttons-container {
    width: 100vw;
    justify-content: space-evenly;
    margin-bottom: 0rem;
  }
  .engine-options-step-title {
    display: none;
  }
  .fuel-type-container {
    justify-content: center;
    margin-left: 0vw;
  }
  .fuel-type-option {
    width: 100px;
    height: 100px;
    margin-right: 2rem;
    margin-left: 2rem;
    border-radius: 50%;
  }
  .engine-options-title-adaptive {
    display: block;
    color: #1e1e1e;
    margin-bottom: 2rem;
  }
  .step-banner {
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 428px) {
  .engine-options-buttons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .engine-type-table-text {
    font-size: 70% !important;
  }
}

.equipment-options-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
  height: 80vh;
  width: 60vw;
}

.equipment-options-buttons-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.equipment-options-button-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

.option-type-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
}

.option-type-option {
  width: 115px;
  height: 60px;
  border: 2px solid #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5%;
}

.option-type-title {
  color: #1e1e1e;
}

.is-active-option {
  background-color: #1e1e1e !important;
  color: #fff !important;
}

.option-type-option:hover {
  background-color: #1e1e1e;
}

.option-type-option:hover .option-type-title {
  color: #fff;
}

.option-container:hover {
  border: #1e1e1e 2px solid;
}

.option-img {
  width: 180px;
  height: 125px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.option-title {
  color: #1e1e1e;
  white-space: nowrap;
}

.option-price {
  color: #1e1e1e;
}

.option-container {
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border: transparent 2px solid;
}

.options-container {
  width: 60vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.equipment-options-title-adaptive {
  display: none;
}

@media screen and (max-width: 1024px) {
  .equipment-options-container {
    margin-top: 4rem;
    margin-bottom: 2rem;
    height: 80%;
    width: 100vw;
  }
  .equipment-options-title-adaptive {
    display: block;
    color: #1e1e1e;
    margin-bottom: 2rem;
  }
  .equipment-options-buttons-container {
    width: 100vw;
    justify-content: space-evenly;
  }
  .equipment-options-button-container {
    width: 100vw;
  }
  .options-container {
    width: 100vw;
    margin-left: 0vw;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .option-container {
    width: 40%;
    height: auto;
    justify-content: space-evenly;
  }
  .option-type-container {
    display: none;
  }
}

@media screen and (max-width: 428px) {
  .equipment-options-buttons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 389px) {
  .option-container {
    width: 50%;
  }
}
.overview-title {
  color: #1e1e1e;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.overview-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.overview-hero {
  width: 100vw;
  height: 75vh;
}

.overview-container-left {
  width: 40vw;
  margin-left: 10vw;
  height: 60vh;
  background: url(/static/media/overview-hero.3178472a.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overview-container-right {
  width: 40vw;
  margin-right: 10vw;
  height: 60vh;
}

.overview-container {
  display: flex;
  flex-direction: row;
}

.overview-model {
  color: #fff;
}

.overview-section-options-table {
  width: 80%;
  height: auto;
  margin-top: 4rem;
  margin-left: 10%;
}

.overview-section-option-row {
  margin-top: 14px;
  height: 30px;
  width: 100%;
  border-bottom: #fff 1px solid;
}

.overview-section-option-title {
  text-align: right;
  color: #fff;
  float: left;
}

.overview-section-option-price {
  text-align: right;
  color: #fff;
  float: right;
}

.overview-section-total-price {
  text-align: right;
  color: #fff;
  margin-right: 10%;
  margin-top: 14px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) {
  .overview-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .overview-hero {
    height: 100%;
  }
  .overview-container {
    display: flex;
    flex-direction: column;
  }
  .overview-container-left {
    width: 100vw;
    margin-left: 0vw;
    height: 30vh;
  }
  .overview-container-right {
    width: 100vw;
    margin-right: 0vw;
    height: 50vh;
  }
  .overview-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  .overview-title {
    margin-bottom: 1rem;
  }
  .overview-section-option-title,
  .overview-section-option-price {
    font-size: 80% !important;
  }
}


/* Popups */
.modal {
  font-size: 12px;
}

.modal > .header {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 2rem;
  margin-top: 4rem;
}

.modal > .header-country {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.modal > .content {
  width: 100%;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 15px;
  top: 15px;
  font-size: 32px;
  background: #fff;
  border: none;
}

.dealer-container {
  width: 80%;
  margin-left: 10%;
  height: 100%;
  border: 2px solid #ccc;
  margin-bottom: 1.5rem;
}

.dealer-container:hover {
  width: 80%;
  margin-left: 10%;
  height: 100%;
  border: 2px solid #000;
  margin-bottom: 1.5rem;
}

.dealer-title {
  margin-top: 1rem;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-before: 1.5rem;
          margin-block-start: 1.5rem;
}

.dealer-location {
  margin-bottom: 1.25rem;
}

/* Base */
.popup-content {
  margin: auto;
  background: #fff;
  width: 50%;
  padding: 5px;
}

[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Media Quieres */
@media screen and (max-width: 768px) {
  .popup-content {
    width: 70% !important;
  }
}

@media screen and (max-width: 428px) {
  .dealer-container {
    width: 90%;
    margin-left: 5%;
  }
  .modal > .header {
    width: 100%;
    margin-left: 0%;
  }
  .popup-content {
    width: 90% !important;
  }
}

.coming-soon-container {
  width: 100vw;
  height: 100vh;
}

.coming-soon-title {
  width: 90%;
  margin-left: 5%;
}

.coming-soon-subtitle {
  margin-bottom: 2rem;
  width: 90%;
  margin-left: 5%;
}

.coming-soon-button-container {
  width: 40vw;
  height: 10vw;
  margin-left: 30vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.coming-soon-img-contianer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.coming-soon-img {
  width: 20vw;
}

/* Media Quieres */
@media screen and (max-width: 1024px) {
  .coming-soon-img {
    width: 60vw;
  }
  .coming-soon-button-container {
    width: 100vw;
    height: 10vw;
    margin-left: 0vw;
  }
  .coming-soon-img {
    width: 40vw;
  }
}

@media screen and (max-width: 768px) {
  .coming-soon-img {
    width: 50vw;
  }
  .coming-soon-subtitle {
    margin-bottom: 5rem;
  }
  .coming-soon-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }
}

@media screen and (max-width: 500px) {
  .coming-soon-img {
    width: 60vw;
  }
}

/* Fonts */
@font-face {
  font-family: Lovelace;
  src: url(/static/media/Lovelace-Regular.b94e0195.ttf);
}
@font-face {
  font-family: Vipnagorgialla;
  src: url(/static/media/VipnagorgiallaRg-Italic.5de4acd2.otf);
}

h1 {
  font-size: 72px;
  line-height: 101px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h2 {
  font-size: 60px;
  line-height: 84px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h3 {
  font-size: 44px;
  list-height: 62px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h4 {
  font-size: 32px;
  line-height: 45px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h5 {
  font-size: 22px;
  line-height: 31px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

h6 {
  font-size: 18px;
  line-height: 25px;
  color: #1e1e1e;
  font-family: Lovelace;
  text-align: center;
}

.body-text-lg {
  font-size: 18px;
  line-height: 24px;
  color: #1e1e1e;
  font-family: "Work Sans", sans-serif;
  text-align: center;
}

.nav-text {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.body-text-md-light {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
}

.body-text-sm-light {
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
}

.arb-light {
  font-family: Vipnagorgialla;
  font-size: 11px;
  line-height: 13px;
  text-align: center !important;
  letter-spacing: 0.03rem;
  color: #fff;
  white-space: nowrap;
}

.arb-dark {
  font-family: Vipnagorgialla;
  font-size: 11px;
  line-height: 13px;
  text-align: center !important;
  letter-spacing: 0.03rem;
  color: #1e1e1e;
  white-space: nowrap;
}

span,
p,
input {
  font-family: "Work Sans", sans-serif;
}

a.button {
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
}

@media screen and (max-width: 1440px) {
  .nav-text {
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .nav-text {
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 44px;
    line-height: 62px;
  }
  h2 {
    font-size: 32px;
    line-height: 45px;
  }
  h3 {
    font-size: 22px;
    line-height: 31px;
  }
  h4 {
    font-size: 18px;
    line-height: 25px;
  }
  .body-text-lg {
    font-size: 16px;
    line-height: 21px;
  }
}

.bg {
    background-color: #fff;
}

.main-1 {
    background-color: #000;
}

.main-2 {
    background-color: #F6F3F1;
}

.main-3 {
    background-color: #1e1e1e;
}

.grey {
    background-color: rgba(255, 255, 255, 0.23);
}

.error {
    background-color: #EB5757;
}

.correct {
    background-color: #219653;
}

.input {
    background-color: #2F80ED;
}

.config-grey {
    background-color: #797777;
}

.config-blue {
    background-color: #2E4A64;
}

.config-gun-metal-grey {
    background-color: #4D4949;
}

.black {
    color: #1e1e1e;
}

.white {
    color: #fff;
}  
